import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useOnScreen } from "../Common/ScreenUtil";

export const RoomScanning = () => {
	const { t } = useTranslation();

	const [playing, setPlaying] = useState(false);
	const ref = useRef();
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) {
			setPlaying(true);
		} else {
			setPlaying(false);
		}
	}, [onScreen]);

	return (
		<div className="base-component component-padding tight">
			<div className="base-component-text-container">
				<h2 className="component-header-normal align-left">{t("functionElement1Header")}</h2>
				<span className="base-component-span" >{t("functionElement1Span")}  </span>
			</div>
			<div className="media-container square-video-container">
				<ReactPlayer
					ref={ref}
					url='https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugartechvideos/roomScan.mp4'
					width="100%"
					height="100%"
					controls={true}
					loop={true}
					muted={true}
					playing={playing}
					config={{ file: { attributes: { controlsList: 'nodownload' } } }}
				/>
			</div>
		</div>
	)
}