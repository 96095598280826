import React from 'react';
import './StyledDiv.css';
import * as DEF from './StyledDivDefs';
import * as R from './StyledDivUtils';

class StyledDiv extends React.Component {
	render() {
		const { container, header, span, list, image, video, wrapper } = this.props;

		const result = (
			<div {...container.attr}>
				<div {...container.textContainer.attr}>
					{R.renderHeader(header)}
					{R.renderSpan(span)}
					{R.renderList(list)}
				</div>
				{ (image.attr.url || image.attr.src || video.attr.url || video.attr.src) &&
					<div {...container.mediaContainer.attr}>
						{R.renderImage(image) || R.renderVideo(video)}
					</div>
				}
				{this.props.children}
			</div>
		);

		return wrapper ? R.renderWrapper(result, wrapper) : result;
	}
}

function StyledDivComponent(props) {
	const _StyledDiv = new DEF._StyledDiv();

	return (
		<StyledDiv {...R.merge(_StyledDiv, props)} />
	);
}

export default StyledDivComponent;
