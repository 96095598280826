import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import loading from "../../animations/loading.json";
import tick from "../../animations/tick.json";

export const PopupSuccess = ({ onClose }) => {
	const { t } = useTranslation();

	return (
		<div className="alertOverlay z15" onClick={onClose}>
			<div className="authCreateAccountContainer alertPopup response-popup">
				<img className="closeIconPopup"
					alt="close icon"
					src="/assets/img/closeIcon.svg"
					onClick={onClose} />
				<div className="affirmativeThanksMessage">
					{t("thanks")}
				</div>
				<Lottie
					loop
					animationData={tick}
					play
					style={{ width: "50%" }}
				/>
				<div className="affirmativeMessage">
					{t('mailAffirmativeContact')}
				</div>
				<div className="affirmativeMail">
					{t('mailAffirmativeContactMessage')}
				</div>
			</div>
		</div>
	)
}

export const PopupLoading = ({ onClose }) => {
	const { t } = useTranslation();

	return (
		<div className="alertOverlay z15" onClick={onClose}>
			<div className="authCreateAccountContainer alertPopup response-popup">
				<img className="closeIconPopup"
					alt="close icon"
					src="/assets/img/closeIcon.svg"
					onClick={onClose} />
				<div className="loadingMessage">
					{t("contactHeaderLoading")}
				</div>
				<Lottie
					loop
					animationData={loading}
					play
					style={{ width: "50%" }}
				/>
				<div className="affirmativeMessage">
					{t("contactSpanLoading")}
				</div>
				<div className="affirmativeMail">
					{t("contactSecondSpanLoading")}
				</div>
			</div>
		</div>
	)
}


export const PopupError = ({ onClose }) => {
	const { t } = useTranslation();

	return (
		<div className="alertOverlay z15" onClick={onClose}>
			<div className="authCreateAccountContainer alertPopup response-popup">
				<img alt="error" src="/assets/img/errorImage.svg" />
				<div className="rejectMessageTitle">
					{t("oops")}
				</div>
				<div className="rejectMessageSpan">
					{t('error')}
				</div>
				<div className="authMailButton" onClick={onClose}>
					<span className="authMailText">
						{t('tryAgain')}
					</span>
				</div>
			</div>
		</div>
	)
}