import { definitions, legalNoticeManifest } from "./LegalNoticeManifest";
import './LegalNoticeCommon.css'

function titleMapper( title ) {
    return (
    <h3 className="title">{ title }</h3>
    );
}

function paragraphMapper( paragraph ) {
    return (
    <p className="paragraph">{ paragraph }</p>
    );
}

function articleMapper( article, index ) {
    return (
        <div className="article">
            { titleMapper( (index + 1) + '. ' + article.title ) }
            <div className="paragraphContainer">
                { article.content.map( paragraphMapper ) }
            </div>
        </div>
    );
}

export default function LegalNoticeComponent({ notice }) {
    return (
        <div className="noticeContainer">
            <h2 className="noticeTitle">{ notice.title }</h2>
            <div className="manifestContainer">
                { titleMapper( definitions.title ) }
                <div className="paragraphContainer">
                    { definitions.content.map( paragraphMapper ) }
                    { legalNoticeManifest.paragraphs.map( paragraphMapper ) }
                </div>
            </div>
            <div className="articleContainer">
                { notice.articles.map( articleMapper ) }
            </div>
        </div>
    );
}