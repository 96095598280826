// import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import './DownloadApp.css';

export const DownloadApp = () => {
	// const { t } = useTranslation();

	// const [isTablet, setIsTablet] = useState(false);
	// const [isMobile, setIsMobile] = useState(false);

	// useEffect(() => {
	// 	const handleResize = () => {
	// 		setIsMobile(window.innerWidth <= 800);
	// 		setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);

	// 	};

	// 	handleResize();

	// 	window.addEventListener('resize', handleResize);

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	// if (isMobile) {
	//     return (
	//         < div className='downloadAppComponent'>
	//             <div className='appMarket'>
	//                 <img className='appMarketLogo' alt='app store logo' src={'/assets/img/downloadAppImages/appStore.svg'}></img>
	//                 <div className='appMarketSpans'>
	//                     {/* <a className='appMarketName'>{t("downloadAppAppstore")}</a> */}
	//                     {/* <div className='appMarketData'>
	//                 <img className='appMarketDataStars' alt='' src={'/assets/img/downloadAppImages/stars.svg'}></img>
	//                 <a className='appMarketDataSpan'>(124,050)</a>
	//             </div> */}
	//                 </div>
	//             </div>
	//             <h2 className='appMarketTitle'>{(t('appMarketTitle'))}</h2>
	//             <div className='appMarket'>
	//                 <img className='appMarketLogo' alt='play store logo' src={'/assets/img/downloadAppImages/playStore.svg'} style={{ opacity: "0.8" }}></img>
	//                 <div className='appMarketSpans'>
	//                     {/* <a className='appMarketName'>{t("downloadAppPlayStore")}</a> */}
	//                     {/* <div className='appMarketData'>
	//                 <img className='appMarketDataStars' alt='' src={'/assets/img/downloadAppImages/stars.svg'}></img>
	//                 <a className='appMarketDataSpan'>(68,050)</a>
	//             </div> */}
	//                 </div>
	//             </div>
	//         </div>

	//     )
	// } else {
	//     return (

	//         < div className='downloadAppComponent' >
	//             <div className='appMarket'>
	//                 <img className='appMarketLogo' alt='app store logo' src={'/assets/img/downloadAppImages/appStore.svg'}></img>
	//                 <div className='appMarketSpans'>
	//                     {/* <a className='appMarketName'>{t("downloadAppAppstore")}</a> */}
	//                     {/* <div className='appMarketData'>
	//                     <img className='appMarketDataStars' alt='' src={'/assets/img/downloadAppImages/stars.svg'}></img>
	//                     <a className='appMarketDataSpan'>(124,050)</a>
	//                 </div> */}
	//                 </div>
	//             </div>
	//             <h2 className='appMarketTitle'>{(t('appMarketTitle'))}</h2>
	//             <div className='appMarket'>
	//                 <img className='appMarketLogo' alt='play store logo' src={'/assets/img/downloadAppImages/playStore.svg'} style={{ opacity: "0.8" }}></img>
	//                 <div className='appMarketSpans'>
	//                     {/* <a className='appMarketName'>{t("downloadAppPlayStore")}</a> */}
	//                     {/* <div className='appMarketData'>
	//                     <img className='appMarketDataStars' alt='' src={'/assets/img/downloadAppImages/stars.svg'}></img>
	//                     <a className='appMarketDataSpan'>(68,050)</a>
	//                 </div> */}
	//                 </div>
	//             </div>
	//         </div >
	//     );
	// }
	return ("");
}
