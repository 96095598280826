import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import StyledDivComponent from "../../components/Common/StyledDiv/StyledDiv";
import { merge } from "../../components/Common/StyledDiv/StyledDivUtils";
import { CompaniesSupport } from "../../components/CompaniesComponents/CompaniesSupport";
import "../../components/CompaniesComponents/Features.css";
import { FeaturesArray } from "../../components/CompaniesComponents/FeaturesArray";
import { FurniturePartner } from "../../components/CompaniesComponents/FurniturePartner";
import { DownloadApp } from "../../components/DownloadAppComponent/DownloadApp";
import PageBanners, { translateBanner } from "../../components/PageBanners/PageBanners";
import "../pageCommon.css";

export const CompaniesPage = () => {
	const { t } = useTranslation();
	const arr2 = FeaturesArray.map((item, index) => {
		return merge(item, {
			wrapper: {
				attr: { className: "component-wrapper" + (index % 2 ? " background-grey" : "") },
			},
			container: {
				attr: { className: "base-component component-padding" + (index % 2 ? " row-reverse" : ""), },
				textContainer: { attr: { className: "companies-component-text-container", }, }
			},
			header: {
				value: t(item.header.value),
				attr: { className: "component-header-normal", },
			},
			span: {
				value: t(item.span.value),
				attr: { className: "base-component-span", }
			},
			list: {
				ulAttr: { attr: {className: "base-component-list", } },
				liAttr: { attr: { className: "base-component-list-item", } },
				items: (item.list && item.list.items) ? item.list.items.map((item) => t(item)) : null,
			},
			image: {
				attr: {
					className: "base-component-image",
					alt: t(item.image.attr.alt),
					src: item.image.attr.src,
				}
			},
		});
	});

	return (
		<div className={'page-margin'}>
			<PageBanners page={translateBanner("companies", t)} />
			<DownloadApp />
			{arr2.map((item, index) => {
				return (
					<Fragment
						key={index}>
						<StyledDivComponent
							{...item}
						/>
					</Fragment>
				);
			})}
			<FurniturePartner />
			<CompaniesSupport />
		</div>
	)
}