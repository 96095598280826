import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnScreen } from '../Common/ScreenUtil';
import StyledDivComponent from '../Common/StyledDiv/StyledDiv';
import './WhatIsRapid.css';

export const WhatIsRapid = () => {
	const { t } = useTranslation();
	const [playing, setPlaying] = useState(false);
	const compRef = useRef(null);
	const onScreen = useOnScreen(compRef);

	useEffect(() => {
		if (onScreen) {
			setPlaying(true);
		} else {
			setPlaying(false);
		}
	}, [onScreen]);

	useEffect(() => {
		fetch('/assets/img/videoThumbnails/thumb_whatIsRapid.webp');
	}, []);

	const component = {
		container: {
			attr: { className: "base-component component-padding", },
			textContainer: { attr: { className: "base-component-text-container", }, },
			mediaContainer: { attr: { className: "square-video-container", }, },
		},
		header: { attr: { className: "component-header-normal", }, },
		span: { attr: { className: "base-component-span", }, },
		list: {
			ulAttr: { attr: { className: "base-component-list", style: { listStyleImage: "url('/assets/img/whatIsRapidImages/circle.svg')", }, }, },
			liAttr: { attr: { className: "base-component-list-item", }, },
			items: [
				"whatIsRapidSpan1",
				"whatIsRapidSpan2",
				"whatIsRapidSpan3",
				"whatIsRapidSpan4",
				"whatIsRapidSpan5",
			],
		},
		video: {
			attr: {
				url: 'https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugartechvideos/whatIsRapid.mp4',
				ref: compRef,
				width: "100%",
				height: "100%",
				// light: "/assets/img/videoThumbnails/thumb_whatIsRapid.webp",
				controls: true,
				loop: true,
				muted: true,
				config: { file: { attributes: { controlsList: {} } } },
				playing: playing,
			},
		},
	};

	component.header.value = t("whatIsRapidApp");
	component.span.value = t("whatIsRapidSpans");
	component.list.items = component.list.items.map(item => t(item));

	return (
		<StyledDivComponent {...component} />
	);
}