import { wait } from "@testing-library/user-event/dist/utils";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useApi } from "../../api/useApi";
import { PopupError, PopupLoading, PopupSuccess } from "../Common/Popup";

export const DemoForm = ({ onClose }) => {
	const api = useApi();
	const { t } = useTranslation();
	const [countryList, setCountryList] = useState([]);
	const [captcha, setCaptcha] = useState(false);
	const [showPopupSuccess, setShowPopupSuccess] = useState(false);
	const [showPopupLoading, setShowPopupLoading] = useState(false);
	const [showPopupError, setShowPopupError] = useState(false);

	const formRef = {
		fullName: useRef(null),
		institutionName: useRef(null),
		institutionEmail: useRef(null),
		phoneNumber: useRef(null),
		interest: useRef(null),
		region: useRef(null),
		helpBusiness: useRef(null),
		userMessage: useRef(null),
	}
	const [form, setForm] = useState({
		fullName: "",
		institutionName: "",
		institutionEmail: "",
		phoneNumber: "",
		interest: "",
		region: "",
		helpBusiness: "",
		userMessage: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});
	};

	const handlePhoneChange = (value) => {
		setForm({
			...form,
			phoneNumber: value,
		});
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm() === false) return;
		try {
			setShowPopupLoading(true);
			const response = await api.institutionalForm(form);
			if (response.status === 200) {
				setShowPopupSuccess(true);
				setTimeout(() => handleClose(), 3000);
			} else {
				setShowPopupError(true);
				setTimeout(() => setShowPopupError(false), 3000);
			}
			setShowPopupLoading(false);
		} catch (error) {
			setShowPopupError(true);
			setShowPopupLoading(false);
			setTimeout(() => setShowPopupError(false), 3000);
		}
	};

	const validateForm = () => {
		const formKeys = Object.keys(form);
		const valid = formKeys.map((key) => {
			console.log(key, formRef[key]);
			let req = formRef[key].current.required || null;
			if (req === false || req === undefined || req === null)
				return true;
			if (form[key] === "" || form[key] === false) {
				formRef[key].current.style.borderColor = "red";
				warnRequired(formRef[key]);
				return false;
			} else if (key === "institutionEmail") {
				const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
				if (!(emailRegex.test(form[key]))) {
					formRef[key].current.style.borderColor = "red";
					warnRequired(formRef[key]);
					return false;
				} else {
					formRef[key].current.style.borderColor = "#BDBDBD";
					return true;
				}
			} else {
				formRef[key].current.style.borderColor = "#BDBDBD";
				return true;
			}
		});

		if (valid.includes(false)) {
			return false;
		}
	}

	const warnRequired = (ref) => {
		if (ref.current.parentNode.querySelector(".warn-required")) return;
		const popup = document.createElement("div");
		popup.className = "warn-required";
		popup.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
			<circle cx="12" cy="12" r="10" fill="#f00" />
			<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="12" fill="#fff">!</text>
		</svg>`
		const parentContainer = ref.current.parentNode;
		parentContainer.style.position = "relative";
		popup.style.position = "absolute";
		popup.style.left = `calc(100% + 10px)`;
		popup.style.top = `calc(100% - 20px)`;
		popup.style.transform = `translateY(-50%)`;
		popup.style.transition = "opacity 0.3s";

		parentContainer.appendChild(popup);
		setTimeout(() => {
			popup.style.opacity = "0";
			wait(300).then(() => {
				popup.remove();
			});
		}, 2000);
	}

	const handleClose = () => {
		onClose();
	}

	useEffect(() => {
		const countries = async () => {
			const response = await api.fetchCountries();
			const countryList = response.data
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((country) => country.name);
			setCountryList(countryList);
		}

		countries();
	}, []);

	return (
		<div>
			{showPopupSuccess && <PopupSuccess onClose={() => setShowPopupSuccess(false)} />}
			{showPopupError && <PopupError onClose={() => setShowPopupError(false)} />}
			{showPopupLoading && <PopupLoading onClose={() => setShowPopupLoading(false)} />}
			<div className="alertOverlay" onClick={onClose}></div>
			<div className="subscriptionForm">
				<img
					className="closeIconPopup"
					onClick={handleClose}
					src="/assets/img/closeIcon.svg"
					alt="close icon" />
				<div className="subscriptionFormContainer">
					<h2 className="subscriptionTitle">
						{t("demoTitle")}
					</h2>
					<span className="subscriptionSpan">
						{t("getFreeEnterpriseDemo")}
					</span>
					<div className="subscriptionRow">
						<div className="formInputContainer">
							<h2 className="formInputHeader">
								{t("fullName")} *
							</h2>
							<input className="formInput"
								required
								name="fullName"
								value={form.fullName}
								ref={formRef.fullName}
								placeholder={t("fullName")}
								onChange={handleChange} />
						</div>
						<div className="formInputContainer">
							<h2 className="formInputHeader">
								{t("institutionEmail")} *
							</h2>
							<input className="formInput"
								required
								name="institutionEmail"
								value={form.institutionEmail}
								ref={formRef.institutionEmail}
								placeholder="hello@rapidrender.com"
								onChange={handleChange} />
						</div>
					</div>
					<div className="subscriptionRow">
						<div className="formInputContainer">
							<h2 className="formInputHeader">
								{t("institutionName")} *
							</h2>
							<input className="formInput"
								name="institutionName"
								value={form.institutionName}
								ref={formRef.institutionName}
								placeholder={t("institutionName")}
								onChange={handleChange} />
						</div>
						<div className="formInputContainer">
							<h2 className="formInputHeader">
								{t("phoneNumber")}
							</h2>
							<PhoneInput
								name="phoneNumber"
								value={form.phoneNumber}
								ref={formRef.phoneNumber}
								country={"tr"}
								containerClass="formInput"
								inputStyle={{
									backgroundColor: "transparent",
								}}
								buttonStyle={{
									backgroundColor: "transparent",
								}}
								onChange={handlePhoneChange}
							/>
						</div>
					</div>
					<div className="subscriptionRow">
						<div className="formInputContainer">
							<h2 className="formInputHeader">
								{t("interests")}
							</h2>
							<select className="formInput"
								required
								name="interest"
								value={form.interest}
								ref={formRef.interest}
								onChange={handleChange}>
								{Array.from({ length: 12 }).map((_, index) => {
									return (
										<option key={index} className="formSelectInterestOptions">
											{t("selectInterest" + index)}
										</option>
									);
								})}
							</select>
						</div>
						<div className="formInputContainer">
							<h2 className="formInputHeader">
								{t("country/region")} *
							</h2>
							<select className="formInput"
								required
								name="region"
								value={form.region}
								ref={formRef.region}
								onChange={handleChange}>
								{countryList.map((country, index) => {
									return <option key={index}>{country}</option>
								})}
							</select>
						</div>
					</div>
					<div className="subscriptionLetter">
						<h2 className="formInputHeader">
							{t("helpBusiness")} *
						</h2>
						<select className="formSelectHelpBusiness"
							name="helpBusiness"
							value={form.helpBusiness}
							ref={formRef.helpBusiness}
							onChange={handleChange}>
							{Array.from({ length: 12 }).map((_, index) => {
								return (
									<option key={index} className="formSelectHelpBusinessOptions">
										{t("helpBusinessOption" + index)}
									</option>
								);
							})}
						</select>
						<textarea className="subscriptionLetterInput"
							placeholder={t("letUsKnowHelp")}
							name="userMessage"
							value={form.userMessage}
							ref={formRef.userMessage}
							onChange={handleChange}>
						</textarea>
					</div>
					{!captcha &&
						<ReCAPTCHA
							sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
							className="reCaptcha"
							onChange={val => setCaptcha(val)}

						/>
					}
				</div>
				<div className="subscriptionSubmitButton"
					style={{
						backgroundColor: captcha ? "#1EA9CD" : "gray",
						cursor: captcha ? "pointer" : "default"
					}}
					onClick={captcha ? handleSubmit : () => { }}>
					<span className="subscriptionSubmitText">
						{t("submit")}
					</span>
				</div>
			</div>
		</div>
	);
};