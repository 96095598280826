import { useTranslation } from "react-i18next";

export const Proforma = () => {
	const { t } = useTranslation();
	return (
		<div className="base-component component-padding column">
			<div className="base-component-text-container-wide">
				<h2 className="component-header-large">{t("functionElement9Header")}</h2>
				<span className="base-component-span">{t("functionElement9Span")}</span>
			</div>
			<img style={{width: "90%", height: "auto"}} alt="proforma"
				src="/assets/img/functionDescriptionsImages/proformaImage.webp" />
		</div>
	)
}