const title = "SUGAR TECHNOLOGY YAZILIM A.Ş. KULLANICI SÖZLEŞMESİ"

const article1 = {
    title: "Hizmet",
    content: [
        "a. Platform size 3B çevrimiçi tasarım aracı uygulamaları, çevrimiçi tasarım işlem bilgilerinin paylaşılması ve çevrimiçi tasarım eğitimi gibi hizmetler sağlar."
    ]
};

const article2 = {
    title: "Kullanım Şartları",
    content: [
        "a. Platforma erişmek ve hizmetleri kullanmak, Kullanıcıinın bu sözleşmeyi kabul ettiğini gösterir.",
        "b. Kullanıcı, Platformu yasalar ve yönetmeliklerle uyumlu bir şekilde kullanmayı taahhüt eder.",
        "c. Kullanıcı, Platformida paylaştığı bilgilerin doğru, güncel ve eksiksiz olduğunu onaylar.",
        "d. Kullanıcı, Platform üzerinden herhangi bir yasa dışı faaliyette bulunmayacağını, başkalarının haklarını ihlal etmeyeceğini ve Platformiun güvenliğini tehlikeye atmayacağını kabul eder.",
        "e. Kullanıcı, Platform üzerindeki herhangi bir içeriği çoğaltmamayı, dağıtmamayı, değiştirmemeyi veya ticari amaçlarla kullanmamayı taahhüt eder."
    ]
};

const article3 = {
    title: "Hesap ve Hesap Güvenliği",
    content: [
        "a. Platform’da Sunar Tech ve RapidRender kullanıcı hesabı ve Facebook, Google, Twitter, Apple gibi üçüncü taraf web sitesi hesapları aracılığı ile oturum açabilir veya anonim bir kullanıcı olarak arama ve bilgi inceleme gibi sınırlı hizmetlerden yararlanabilirsiniz.",
        "b. Oluşturduğunuz kullanıcı hesabınız dahilinde gerçekleştirdiğiniz tüm işlemlerden siz sorumlusunuz. Bir kullanıcı hesabına sahip olmanızın, Platform’un sağladığı tüm hizmetlerden faydalanabileceğiniz anlamına gelmediğini lütfen unutmayın.",
        "c. Oluşturacağınız kullanıcı hesabını sonlandırma hakkını saklı tutuyorsunuz. Kullanıcı hesabınızı kullanmamaya karar vermeniz durumunda, kullanıcı hesabınızı resmi olarak iptal edebileceksiniz.",
        "d. Kullanıcı hesabınızın kapatılması durumunda birkaç durum meydana gelecektir. Öncelikle, hesabınızı kullanma hakkınız derhal durdurulacaktır. İkinci olarak, yürürlükteki yasalar uyarınca hesabınıza ve kullanım geçmişinize dair verileri saklamamız, iade etmemiz veya size ya da üçüncü bir tarafa aktarmamız gerekmediği sürece, ilgili tüm veriler silinecektir veya hesabınıza dair tüm ilişkiniz sonlandırılacaktır.",
        "e. Kullanıcı hesabınıza dair bilgileriniz, şifreniz, doğrulama kodunuz ve diğer tüm bilgileri dikkatli bir şekilde saklayın. Kullanıcı hesabınız yalnızca sizin tarafınızdan kullanılabilir ve devredilmesi, takas edilmesi, bağışlanması, kiralanması veya ödünç alınması yasaktır. Bu kapsamda tüm sorumluluk üzerinizdedir ve olası bir ihlalin tespiti durumunda Şirket, herhangi bir bildirimde bulunmaksınız yasaklama ve tarafınızı korumaya yönelik yasal sorumluluğu üzerine almama hakkını saklı tutar.",
        "f. İşlem güvenliğini sağlamak adına Platform, uygulama ve hizmetlerde bulunan bazı özellikleri etkin bir şekilde kullanabilmeniz için sizden kimlik doğrulaması talep edebilir.",
        "g. Kullanıcı hesabınızın yetkisiz kullanımı veya hesap güvenliğinin başka türlü ihlal edilmesi durumunda Sugar Technology Yazılım A.Ş’yi derhal bildilendirmeyi kabul edersiniz. Şirket, başka birinin sizin hesabınızı kullanması sonucunda uğrayabileceğiniz hiçbir zarardan mesul olmayacaktır."
    ]
};

const article4 = {
    title: "Hizmet Bedeli",
    content: [
        "Platform’un bazı hizmetlerinde belirli hizmet ücretleri alınacaktır. Ayrıntılar için sitemizde yer alan fiyatlandırma ile ilgili kısımlara bakabilir ya da destek ekibimizle iletişime geçebilirsiniz. Ayrıca, site ve uygulama kapsamında bulunan hizmet ve özellikler arasında ücretsiz bir şekilde yararlanabileceğiniz hizmetler de olacaktır."
    ]
};

const article5 = {
    title: "Gizlilik ve Veri Koruma",
    content: [
        "a. Şirket, Kullanıcıinın Platformida sağladığı kişisel bilgileri gizli tutmayı ve ilgili veri koruma mevzuatına uygun olarak işlemeyi taahhüt eder.",
        "b. Kullanıcı, Şirketiin Platform üzerindeki veri toplama, kullanma ve paylaşma politikalarını kabul eder."
    ]
};

const article6 = {
    title: "Sorumluluk Sınırlaması",
    content: [
        "a. Kullanıcı, Platformiu kendi sorumluluğunda ve riskinde kullanır. Şirket, Platformiun kesintisiz, hatasız veya güvenli olduğunu garanti etmez.",
        "b. Şirket, Kullanıcıinın Platformida yer alan içeriklere veya üçüncü taraf sitelere erişimi veya bunlarla etkileşimi sonucu ortaya çıkan herhangi bir zarardan sorumlu değildir.",
        "c. Platform’un aşağıdaki eylemleri kolaylaştırmak veya desteklemek için kullanılamayacağını veya aşağıdaki eylemlerle bağlantılı olarak üretilemeyeceğini, yüklenemeyeceğini, saklanamayacağını, dağıtılamayacağını veya dağıtılamayacağını kabul ve garanti ediyorsunuz:",
        "i. Müstehcenlik, pornografi, kumar, şiddet, cinayet, terörizm veya suçlara yataklık etmek;",
        "ii. Başkalarına hakaret etmek, iftira atmak veya başkalarının yasal hak ve çıkarlarını ihlal etmek.",
        "d. Platform’un kullanımının bu Sözleşmeye ve ilgili iş kurallarına ve kontrollerine tabi olduğunu ve aşağıdaki eylemler dahil ancak bunlarla sınırlı olmamak üzere aşağıdaki eylemleri gerçekleştirmek veya aşağıdaki eylemleri kolaylaştırmak için kullanılamayacağını kabul ve garanti edersiniz:",
        "i. Gerçek isim kimlik doğrulaması için doğruluk olmaksızın bilgi sağlamak;",
        "ii. Kullanıcı hesabınızı devretmek, satmak, kiralamak, ödünç vermek veya başkalarıyla paylaşmak;",
        "iii. Virüsler, truva atları, kötü amaçlı kod, kimlik avı, kötü niyetli tarama, yasa dışı izinsiz giriş, yetkisiz erişim vb. dahil ancak bunlarla sınırlı olmamak üzere ağın güvenliğine herhangi bir zarar vermek veya zarar vermeye teşebbüs etmek;",
        "iv. Platform tarafından sağlanan sistem konfigürasyonunda herhangi bir değişiklik yapmak veya değiştirme girişiminde bulunmak ya da Platform’un güvenliğini tehlikeye atmak;",
        "v. Platform’a ait tüm telif hakkı bilgilerinin silinmesi, Platform’da tersine mühendislik, ters derleme, ters derleme yapılması veya Platform’un yazılım kaynak kodunun başka yollarla bulunmaya çalışılması;",
        "vi. Platform’un ticari olarak kopyalamak, değiştirmek, üzerinde değişiklik yapmak, Platform’a ticari olarak bağlantı vermek veya herhangi bir türev eser veya ürün oluşturmak ya da Platform’a erişmek, müdahale etmek veya Platform’u etkilemek için eklentiler, veya yetkisiz araçlar kullanmak;",
        "vii. Platform’daki robots.txt veya benzer dosyaları ihlal etmek;",
        "ix. Platform ile ilgili verileri ticari olarak kullanmak, kiralamak, ödünç vermek, kopyalamak, değiştirmek, bağlamak, yeniden basmak, derlemek, yayınlamak veya ayna siteleri oluşturmak;",
        "x. Kötü niyetle yapılan veya Platformidaki herhangi bir derecelendirmeye veya yoruma zarar verebilecek herhangi bir işlemin gerçekleştirilmesi ve Platform’un bütünlüğünü veya doğruluğunu etkileyebilecek herhangi bir eylemde bulunulması;",
        "xi. Platformiun işleyişini ve Platformuin diğer kullanıcılar tarafından kullanımını yok etmek ve aksatmak için teknoloji veya başka yöntemler kullanmak;",
        "xii. Platform’a erişim veya bu platformun kullanılabilirliğine ilişkin her türlü kısıtlamayı aşmak;",
        "xiii. Başkalarının haklarını ihlal etmek veya başkalarının gizlilik veya veri koruma haklarını ihlal eden faaliyetlerde bulunmak;",
        "xiv. Başkalarının bu kuralları çiğnemesine yardımcı olmak.",
        "xv. Şirket, davranışınızın yürürlükteki yasaları veya bu Sözleşmeyi kendisi tarafından veya diğer kuruluşlar veya kişiler tarafından toplanan bilgilere göre ihlal ettiğini tespit ederse, Sugar Technology Yazılım A.Ş, bağımsız kararına dayanarak hesabınızı ve içeriklerinizi silmek, engellemek veya bu içeriklere ait bağlantıların kesilmesi, Platform’un sunduğu imkan ve hizmetlere erişimin sınırlı bir şekilde ya da tamamen sonlandırılması, hesabınızın askıya alınması gibi önlem haklarına sahiptir."
    ]
};

const article7 = {
    title: "Fikri Mülkiyet Hakları",
    content: [
        "a. Platform üzerinde yer alan tüm içeriklerin fikri mülkiyet hakları Şirketie aittir. Kullanıcı, Platformida yer alan içerikleri Şirketiin izni olmadan kullanamaz veya çoğaltamaz.",
        "b. Kullanıcı, Platformia yüklediği içeriklerin Şirket tarafından kullanılması ve yayınlanması için Şirketie sınırlı, geri alınabilir ve telifsiz bir lisans verdiğini kabul eder."
    ]
};

const article8 = {
    title: "Sözleşmenin Sonlandırılması",
    content: [
        "a. Kullanıcı, Platformidan herhangi bir zamanda çıkış yaparak bu sözleşmeyi sonlandırabilir.",
        "b. Şirket, Kullanıcıinın bu sözleşmeyi ihlal etmesi durumunda, bildirimde bulunmadan veya Kullanıcıiya herhangi bir tazminat ödemeden bu sözleşmeyi sonlandırma hakkını saklı tutar."
    ]
};

const article9 = {
    title: "Değişiklikler",
    content: [
        "a. Şirket, bu sözleşmeyi kendi takdirine bağlı olarak dilediği zaman değiştirme hakkına sahiptir.",
        "b. Değişiklikler, Şirket tarafından Platform üzerinden yayınlanarak yürürlüğe girer. Kullanıcı, değişiklikleri takip etmekle yükümlüdür."
    ]
};

const article10 = {
    title: "Uyuşmazlıkların Çözümü",
    content: [
        "a. Bu sözleşme Türk hukukuna tabidir ve Türkiye mahkemeleri yetkilidir.",
        "b. Taraflar arasında çıkacak uyuşmazlıkların çözümünde Türkiyeideki yetkili mahkemelerin münhasır yetkisi vardır."
    ]
};

const termsOfUseContent = {
    title: title,
    articles: [
        article1,
        article2,
        article3,
        article4,
        article5,
        article6,
        article7,
        article8,
        article9,
        article10
    ]
};

export { termsOfUseContent };