import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faqContent } from './FAQContent';
import './SSS.css';

export const SSS = () => {
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const array = faqContent;
	const [open, setOpen] = useState(new Array(array.length).fill(false));

	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	});

	const handleClick = (index) => {
		setOpen(open.map((item, i) => i === index ? !item : false))
	}

	const generateAnswer = (answerList) => {
		return answerList.map((answer, index) => {
			if (answer.type === "header") {
				return <h3 key={index}>{t(answer.id)}</h3>
			} else if (answer.type === "span") {
				return <span key={index}>{t(answer.id)}</span>
			} else {
				return <ul key={index}>
					{answer.items.map((item, index) => (
						<li key={index}>
							{t(item)}
						</li>))}
				</ul>;
			}
		});
	}

	const elements = array.map((content, index) => (
		<Fragment key={index}>
			<div className='faq-item' onClick={() => handleClick(index)}>
				<div className="faq-question">
					<span>
						{t(content.q)}
					</span>
					<img src='/assets/img/sss/vector.svg' alt='a dropdown arrow'
						style={{ transform: open[index] ? "rotate(180deg)" : "", }} />
				</div>
				{open[index] &&
					<div className="faq-answer">
						{generateAnswer(content.a)}
					</div>
				}
			</div>
			<div className='faq-item-divider'></div>
		</Fragment>
	))

	const createColumns = () => {
		if (windowWidth > 890) {
			const column1 = elements.slice(0, Math.ceil(elements.length / 2));
			const column2 = elements.slice(Math.ceil(elements.length / 2));
			return (
				<div className="faq-column-wrapper">
					<div className='faq-column'>{column1}</div>
					<div className='faq-column'>{column2}</div>
				</div>
			);
		} else {
			return (
				<div className='faq-column-wrapper'>
					<div className='faq-column'>{elements}</div>
				</div>
			);
		}
	}

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<div className="base-component-text-container-wide">
					<h2 className='component-header-large'>{t('faqHeader')}</h2>
					<span className='base-component-span'>{t('faqSpan')}</span>
				</div>
				{createColumns()}
			</div>
		</div>
	);
}