import StyledDivComponent from "../Common/StyledDiv/StyledDiv";
import { merge } from "../Common/StyledDiv/StyledDivUtils";

const bannerMap = new Map([
	["interiorDesigners", {
		wrapper: { attr: { style: { backgroundImage: "url('/assets/img/pageBanners/interiorDesignersBanner.webp')", }, }, },
		header: { value: "architectsTitle", },
		span: { value: "architectsSubtitle", },
		image: { attr: { src: "/assets/img/pageBanners/interiorDesignersTinyRoom.webp", alt: "predesigned room", }, },
	}],

	["companies", {
		wrapper: { attr: { style: { backgroundImage: 'url("/assets/img/pageBanners/companiesBanner.webp")', }, }, },
		header: { value: "rapidRenderProvides", },
		span: { value: "companiesSubtitle", },
		image: { attr: { src: "/assets/img/pageBanners/companiesTinyRoom.webp", alt: "predesigned room", }, },
	}],

	["libraries", {
		wrapper: { attr: { style: { backgroundImage: "url('/assets/img/pageBanners/librariesBanner.webp')", }, }, },
		header: { value: "librariesHeader", },
		span: { value: "librariesSpan", },
		image: { attr: { src: "/assets/img/pageBanners/librariesTinyRoom.webp", alt: "content libraries", }, },
	}],
]);

const pageBannerBase = {
	wrapper: {
		attr: {
			className: "page-banner-wrapper",
			style: { backgroundSize: "cover", backgroundRepeat: "no-repeat", },
		},
	},
	container: {
		attr: { className: "page-banner", },
		textContainer: { attr: { className: "page-banner-text-container", }, },
	},
	header: { attr: { className: "page-banner-header" }, },
	span: { attr: { className: "page-banner-span" }, },
	image: { attr: { fetchpriority: "high", className: "page-banner-image", }, },
};

bannerMap.forEach((value, key, map) => {
	map.set(key, merge(pageBannerBase, value));
});

// Banner does not update when the language changes, so we need to call this function again
const translateBanner = (banner, t) => {
	const translatedBanner = bannerMap.get(banner);
	return merge(translatedBanner, {
		header: { value: t(translatedBanner.header.value), },
		span: { value: t(translatedBanner.span.value), },
	});
}

const PageBanners = ({ page }) => {
	return (
		<StyledDivComponent
			{...page}>
		</StyledDivComponent>
	);
}


export default PageBanners;
export { bannerMap, translateBanner };
