import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './Header.css';
import LanguageSelector from './LanguageSelector';

export const Header = () => {
	const { t } = useTranslation();
	const [isRouteMenuOpen, setIsRouteMenuOpen] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [lastScrollTop, setLastScrollTop] = useState(0);
	const headerRef = useRef(null);
	const popupRef = useRef(null);
	const buttonRef = useRef(null);

	const handleClick = useCallback((e) => {
		if (isRouteMenuOpen) {
			popupRef.current.classList.add("hidden");
		} else {
			popupRef.current.classList.remove("hidden");
		}
		setIsRouteMenuOpen(!isRouteMenuOpen);
	}, [isRouteMenuOpen]);

	const handleMouseLeave = useCallback((e) => {
		if (windowWidth <= 890) {
			popupRef.current.classList.add("hidden");
			setIsRouteMenuOpen(false);
		}
	}, [windowWidth]);

	const handleOutsideClick = (e) => {
		if (buttonRef.current && !buttonRef.current.contains(e.target)
			&& popupRef.current && !popupRef.current.contains(e.target)
			&& windowWidth <= 890) {
			popupRef.current.classList.add("hidden");
			setIsRouteMenuOpen(false);
		} else if (popupRef.current && popupRef.current.contains(e.target) && windowWidth <= 890) {
			// Immediate closure prevents navigation
			setTimeout(() => {
				popupRef.current.classList.add("hidden");
				setIsRouteMenuOpen(false);
			}, 500);
		}
	};

	const handleResize = (e) => {
		setWindowWidth(window.innerWidth);
		if (windowWidth > 890) {
			popupRef.current.classList.remove("hidden");
			setIsRouteMenuOpen(false);
		} else {
			popupRef.current.classList.add("hidden");
			setIsRouteMenuOpen(false);
		}
	};

	let animationScheduled = false;

	const handleScroll = (e) => {
		const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

		if (animationScheduled) return;

		animationScheduled = true;// Prevents multiple calls

		if (document.documentElement.scrollTop > 70
			&& window.scrollY > 100
			&& currentScrollTop > lastScrollTop
			&& headerRef.current) {
			headerRef.current.style.transform = "translateY(-100%)";
		} else {
			headerRef.current.style.transform = "translateY(0%)";
		}
		setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
		animationScheduled = false;
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		window.addEventListener('scroll', handleScroll);
		document.addEventListener('click', handleOutsideClick);
		document.addEventListener('touchstart', handleOutsideClick);
		return () => {
			window.removeEventListener('resize', handleResize);
			document.removeEventListener('click', handleOutsideClick);
			document.removeEventListener('touchstart', handleOutsideClick);
			window.removeEventListener('scroll', handleScroll);
		};
	});

	return (
		<div className="header-component" ref={headerRef}>
			<div className="header-content-container">
				{
					windowWidth <= 890 &&
					<div className="routers-button-wrapper"
						ref={buttonRef}
						onClick={handleClick}>
						<img
							className="routers-button"
							alt='three dots, representing a menu dropdown'
							src={'/assets/img/headerImages/popupButton.svg'} />
					</div>
				}
				<div className="header-logo-container">
					<Link to="/" className="header-logo">
						<img alt='rapid render logo' src={'/assets/img/headerImages/rapidRenderHeaderLogo.svg'}></img>
					</Link>
				</div>
				<div className={"header-router-container" + (windowWidth <= 890 ? " hidden" : "")}
					ref={popupRef}
					onMouseLeave={handleMouseLeave}>
					<Link to="/" className="header-router">{t("homePage")}</Link>
					<Link to="/interiorDesigners" className="header-router">{t("forArchitect")}</Link>
					<Link to="/companies" className="header-router">{t("forCompany")}</Link>
					<Link to="/libraries" className="header-router">{t("libraries")}</Link>
					{/* <Link to="/blogs" className="header-router">{t("blogs")}</Link> */}
					<Link to="/contact" className="header-router">{t("contact")}</Link>
				</div>
				<LanguageSelector />
			</div>
		</div>
	);
}
// import React, { useContext, useEffect, useState } from 'react';
// import Lottie from 'react-lottie-player';
// import { Link, useNavigate } from 'react-router-dom';
// import lottieFile from "../../animations/tick.json";
// import { useApi } from '../../api/useApi';
// import { AuthContext } from '../../auth/AuthProvider';
// import { useStringLocalStorage } from '../../storage/useStorage';

// export const Header = () => {
// 	const { t } = useTranslation();
// 	const [isTablet, setIsTablet] = useState(false);
// 	const [isMobile, setIsMobile] = useState(false);
// 	const [, setIsPopupShow] = useState(false);
// 	const [, setIsShowAuth] = useState(false);
// 	const [isSuccess, setIsSuccess] = useState(false);
// 	const [isError, setIsError] = useState(false);
// 	const navigate = useNavigate();
// 	const auth = useContext(AuthContext);
// 	const [userMail,] = useStringLocalStorage(
// 		"userMail",
// 		""
// 	);
// 	const [authorisationCode,] = useStringLocalStorage(
// 		"authorisationCode",
// 		""
// 	);
// 	const [errorMessage, setErrorMessage] = useState("Beklenmedik bir hata oluştu lütfen tekrar deneyin.")

// 	const api = useApi();
// 	useEffect(() => {
// 		const handleResize = () => {
// 			setIsMobile(window.innerWidth <= 800);
// 			setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);
// 		};

// 		handleResize();

// 		window.addEventListener('resize', handleResize);

// 		return () => {
// 			window.removeEventListener('resize', handleResize);
// 		};
// 	}, []);

// 	function showMobilePopup() {
// 		let popup = document.querySelector(".mobileRoutersPopup");
// 		let overlay = document.querySelector(".mobileRoutersPopupOverlay");
// 		if (popup.style.display === "none") {
// 			popup.style.display = "flex";
// 			overlay.style.display = "block";
// 			setIsPopupShow(true);
// 		} else {
// 			popup.style.display = "none";
// 			overlay.style.display = "none";
// 			setIsPopupShow(false);
// 		}
// 	}

// 	const onSuccessAuth = () => {
// 		setIsSuccess(true);
// 		auth.setShowFalse();
// 	}
// 	const onErrorAuth = () => {
// 		setIsError(true);
// 		auth.setShowFalse();
// 	}
// 	const handleClickDemo = async () => {
// 		if (authorisationCode && authorisationCode !== "") {
// 			try {
// 				await api.sendLink(userMail);
// 				setIsSuccess(true);
// 			} catch (e) {
// 				if (e.response.data.code === "1")
// 					setErrorMessage("Timeout please wait 5 minutes")
// 				setIsError(true);
// 			}
// 		} else {
// 			auth.runAuthorization(onSuccessAuth, onErrorAuth);
// 			setIsShowAuth(true);
// 		}
// 	}

// 	return (
// 		<div className={`${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} headerComponent`} >
// 			<div className="headerContainer">
// 				<div className='alertOverlay' style={{ display: isSuccess ? "flex" : "none" }}>
// 					<div className="authCreateAccountContainer" style={{ paddingInline: !isMobile && "80px" }}>
// 						<img alt='close icon' src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setIsSuccess(false) }} />
// 						<div className="affirmativeThanksMessage">
// 							{t("thanks")}
// 						</div>
// 						<Lottie
// 							loop
// 							animationData={lottieFile}
// 							play
// 							style={{ width: "50%" }}
// 						/>
// 						<div className="affirmativeMessage">
// 							{t('mailAffirmativeDemo')}
// 						</div>
// 						<div className="affirmativeMail">
// 							{userMail}
// 						</div>
// 					</div>
// 				</div>

// 				<div className='alertOverlay' style={{ display: isError ? "flex" : "none" }}>
// 					<div className="authCreateAccountContainer" style={{ paddingInline: !isMobile && "80px" }} >
// 						<img alt='close icon' src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setIsError(false) }} />
// 						<img alt='error' src="/assets/img/errorImage.svg" />
// 						<div className="rejectMessageTitle">
// 							{t("oops") + " " + t('error')}
// 						</div>
// 						<div className="rejectMessageSpan">
// 							{errorMessage}
// 						</div>
// 						<div className="authMailButton" onClick={() => { setIsError(false); }}>
// 							<span className="authMailText">
// 								{t('tryAgain')}
// 							</span>
// 						</div>
// 					</div>
// 				</div>

// 				<div className='headerLogoContainer' >
// 					<div style={{ width: "30px", height: "100%", display: isMobile ? "flex" : "none", justifyContent: "flex-start" }} onClick={showMobilePopup}>
// 						<img className='mobileRoutersPopupButton' alt='popup menu' src={'/assets/img/headerImages/popupButton.svg'}></img>
// 					</div>
// 					<Link to="/" style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
// 						<img className='headerLogo' alt='rapid render logo' src={'/assets/img/headerImages/rapidRenderHeaderLogo.svg'}></img>
// 					</Link>
// 				</div>
// 				<div className='headerRouterContainer'>
// 					<a className="headerRouter" href='/'>{t("homePage")}</a>
// 					<a className="headerRouter" href='interiorDesigners'>{t("forArchitect")}</a>
// 					<a className="headerRouter" href='companies'>{t("forCompany")}</a>
// 					<a className="headerRouter" href='libraries'>{t("libraries")}</a>
// 					{/* <a className="headerRouter" href='pricingOptions'>{t("headerPricing")}</a> */}
// 					<a className="headerRouter" href='blogs'>{t("blogs")}</a>
// 					<a className="headerRouter" href='contact'>{t("contact")}</a>
// 				</div>
// 				<div className='headerButtons' >
// 					{/* <span className='demoButton' onClick={() => { handleClickDemo() }}>{t("demo")}</span> */}
// 					<LanguageSelector></LanguageSelector>
// 				</div>
// 				<div className="mobileRoutersPopupOverlay" id='mobileRoutersPopupOverlay' style={{ zIndex: "1" }} onClick={showMobilePopup}></div>
// 				<div className='mobileRoutersPopup'>
// 					<div className='mobileRouters'>
// 						<div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
// 							<img alt='RapidRender logo' style={{ alignSelf: "flex-start", padding: "0px 0px 15px 15px" }} src='/assets/img/mobilHeaderLogo.svg' />
// 							<div style={{ width: "100%", height: "1px", opacity: "0.12", background: "#fff" }}></div>
// 						</div>
// 						<div className='mobilRoutersDiv' onClick={() => { navigate("/"); showMobilePopup(); }} >
// 							<a href='/'>{t("homePage")}</a>
// 						</div>
// 						<div className='mobilRoutersDiv' onClick={() => { navigate("architects"); showMobilePopup(); }}>
// 							<a href='architects'>{t("forArchitect")}</a>
// 						</div>
// 						<div className='mobilRoutersDiv' onClick={() => { navigate("companies"); showMobilePopup(); }}>
// 							<a href='companies'>{t("forCompany")}</a>
// 						</div>
// 						<div className='mobilRoutersDiv' onClick={() => { navigate("libraries"); showMobilePopup(); }}>
// 							<a href='libraries'>{t("libraries")}</a>
// 						</div>
// 						{/* <div className='mobilRoutersDiv' onClick={() => { navigate("pricingOptions"); showMobilePopup(); }}>
//                         <a href='pricingOptions'>{t("headerPricing")}</a>
//                     </div> */}
// 						<div className='mobilRoutersDiv' onClick={() => { navigate("blogs"); showMobilePopup(); }}>
// 							<a href='blogs'>{t("blogs")}</a>
// 						</div>
// 						<div className='mobilRoutersDiv' onClick={() => { navigate("contact"); showMobilePopup(); }}>
// 							<a href='contact'>{t("contact")}</a>
// 						</div>
// 						<div className='demoButtonMobilDivview' style={{ display: "flex", width: "100%" }}>
// 							<span className='demoButtonMobil' onClick={() => { handleClickDemo() }}>{t("demo")}</span>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }