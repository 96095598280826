import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderWithLine } from "../Common/HeaderWithLine";
import "./Products.css";

export const ProductsPlating = () => {
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const productsArray = [
		{
			url: '/assets/img/productImages/m1.webp',
			header: t("plating1"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m2.webp',
			header: t("plating2"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m3.webp',
			header: t("plating3"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m4.webp',
			header: t("plating4"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m5.webp',
			header: t("plating5"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m6.webp',
			header: t("plating6"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m7.webp',
			header: t("plating7"),
			productId: "Designer_34874",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/m8.webp',
			header: t("plating8"),
			productId: "Designer_34874",
			companyId: "43",
		}
	]

	return (
		<div className="component-wrapper background-grey">
			<div className="base-component component-padding column">
				<HeaderWithLine
					title={t("plating")}
					color="#1EA9CD"
					size="large"
					position={windowWidth <= 890 ? "relative" : "absolute"}
					transform={windowWidth <= 890 ? ""
						: windowWidth <= 1050 ? "translateY(-102%)"
							: windowWidth <= 1494 ? "translateY(-129%)"
								: "translateY(-207%)"} />
				<div className='base-component-text-container-wide'>
					<span className='base-component-span'>{t("platingSpan")}</span>
				</div>
				<div className="product-item-container">
					{productsArray.map((item, index) => (
						<div key={index} i={index} className={"productItem"} >
							<img src={item.url} className="productsImage" alt="Product" />
							<h2 className="productItemHeader">{item.header}</h2>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}