import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StyledDivComponent from '../Common/StyledDiv/StyledDiv';
import './PageBanner.css';

export const HomeBanner = () => {
	const [t] = useTranslation();

	useEffect(() => {
		fetch('/assets/home_slider_h264.webm');
	}, []);

	const component = {
		wrapper: { attr: { className: "home-banner-wrapper" } },
		container: {
			attr: { className: "home-banner" },
			textContainer: { attr: { className: "home-overlay", }, },
		},
		header: {
			value: t("liveHomeHeader"),
			attr: { className: "component-header-normal align-left" },
		},
		span: {
			value: t("liveHomeSpan"),
			attr: { className: "page-banner-span align-left" },
		},
	};


	return (
		<>
			<StyledDivComponent {...component} />
			<video
				style={{ width: "100%", height: "100%", borderRadius: "0", boxShadow: "0 0 0 0" }}
				fetching="eager"
				poster='/assets/img/videoThumbnails/thumb_homeBanner.webp'
				autoPlay
				muted
				loop
				playsInline
				preload="auto">
				<source src="https://s3.eu-central-1.amazonaws.com/cdn.sugartech/local/rapidRenderVideos/home_slider_h264.webm" type='video/webm' />
				<source src="https://s3.eu-central-1.amazonaws.com/cdn.sugartech/local/rapidRenderVideos/home_slider_h264.mp4" type="video/mp4" />
			</video>
		</>
	);
}