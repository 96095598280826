import { useTranslation } from "react-i18next";

export const WhatsAppAndEmail = () => {
	const { t } = useTranslation();

	return (
		<div className="base-component component-padding tight">
			<div className="media-container">
				<img alt="whatsapp and email logos"
					src="/assets/img/functionDescriptionsImages/whatsAppAndEmailImage.webp" />
			</div>
			<div className="base-component-text-container" >
				<h2 className="component-header-normal">{t("functionElement16Header")}</h2>
				<span className="base-component-span">{t("functionElement16Span")}</span>
			</div>
		</div>
	)
}