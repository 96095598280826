import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player';
import { useOnScreen } from "../Common/ScreenUtil";
import './FurniturePartner.css';

export const FurniturePartner = props => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const onScreen = useOnScreen(ref);
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		if (onScreen) {
			setPlaying(true);
		} else {
			setPlaying(false);
		}
	}, [onScreen]);

	return (
		<div className="furniture-partner-component">
			<div className='furniture-partner-container'>
				<div id="header-grid">
					<img id="top-quote" alt='listen to partner' src={'/assets/img/listenToPartnerImages/ListenToPartnerImg1.svg'} />
					<img id="partner-logo" alt='tepe home logo' src={'/assets/img/listenToPartnerImages/tepe-home-min.svg'} />
					<h2 id="partner-header">{t("listenToPartnerHeader")}</h2>
					<img id="bottom-quote" alt='listen to partner' src={'/assets/img/listenToPartnerImages/ListenToPartnerImg2.svg'} />
					<span id="partner-span">{t("tepeHomeComments")}</span>
					<h2 id="partner-title">{t("tepeHomeCommentsTitle")}</h2>
				</div>
				<div className='partner-video-container'>
					<ReactPlayer
						ref={ref}
						url='https://www.youtube.com/embed/C-aoRasmfX8'
						width={"100%"}
						height={"100%"}
						controls={true}
						loop={false}
						muted={false}
						playing={playing}
						pip={false}
						config={{ file: { attributes: { controlsList: '' } } }}
					/>
				</div>
			</div>
		</div>
	);
}