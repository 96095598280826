import { useTranslation } from "react-i18next";
import "./LibraryFinal.css";

export const LibraryFinal = () => {
	const { t } = useTranslation();
	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<div className="base-component-text-container-wide">
					<h2 className="component-header-large">{t("dataText")}</h2>
					<span className="base-component-span">{t("dataTextSpan")} </span>
				</div>
				<div className="whatsNewElements">
					<img className="libraryFinalImage" alt='library' src={'/assets/img/libraryFinal.svg'}></img>
					<img className="mainImage" alt='shadow' src={'/assets/img/libraryFinalShadow.svg'}></img>
				</div>
			</div>
		</div>
	)
}