import { useRef } from "react";
import { useTranslation } from "react-i18next";
import "./Products.css";

export const ProductsFurniture = () => {
	const { t } = useTranslation();
	const productsArray = [
		{
			url: '/assets/img/productImages/f01.webp',
			header: t("furniture1"),
			productId: "1952_20429",
			companyId: "37",
		},
		{
			url: '/assets/img/productImages/f02.webp',
			header: t("furniture2"),
			productId: "arcadia_34758",
			companyId: "24",
		},
		{
			url: '/assets/img/productImages/f03.webp',
			header: t("furniture3"),
			productId: "Designer_3940221726",
			companyId: "1002",
		},
		{
			url: '/assets/img/productImages/f04.webp',
			header: t("furniture4"),
			productId: "Designer_36374",
			companyId: "8",
		},
		{
			url: '/assets/img/productImages/f05.webp',
			header: t("furniture5"),
			productId: "Designer_33760",
			companyId: "852",
		},
		{
			url: '/assets/img/productImages/f06.webp',
			header: t("furniture6"),
			productId: "1_15059",
			companyId: "32",
		},
		{
			url: '/assets/img/productImages/f08.webp',
			header: t("furniture7"),
			productId: "Designer_33801",
			companyId: "652",
		},
		{
			url: '/assets/img/productImages/f11.webp',
			header: t("furniture8"),
			productId: "Designer_35866",
			companyId: "42",
		},
		{
			url: '/assets/img/productImages/f09.webp',
			header: t("furniture9"),
			productId: "Designer_34958",
			companyId: "1002",
		},
		{
			url: '/assets/img/productImages/f10.webp',
			header: t("furniture10"),
			productId: "Designer_33775",
			companyId: "852",
		},
		{
			url: '/assets/img/productImages/f07.webp',
			header: t("furniture11"),
			productId: "Designer_32151",
			companyId: "43",
		},
		{
			url: '/assets/img/productImages/f12.webp',
			header: t("furniture12"),
			productId: "Designer_37370",
			companyId: "42",
		},
	]

	const sugarRef = useRef();
	const popupRef = useRef();

	const handleEscape = (e) => {
		if (e.key === 'Escape') {
			onClose();
		}
	};

	const openPopup = (index) => {
		const existingScript = document.getElementById('sugar-model-viwer-js');
		if (!existingScript) {
			const script = document.createElement('script');
			script.src = 'https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugar-model-viewer-test.js';
			script.id = 'sugar-model-viwer-js';
			script.type = 'module';
			document.body.appendChild(script);
		}

		const sugarModelViewerContainer = sugarRef;
		console.count(sugarModelViewerContainer.current);
		sugarModelViewerContainer.current.innerHTML = "";
		setTimeout(() => {
			sugarModelViewerContainer.current.innerHTML = `
            <sugar-model-viewer class="modelViewer"
                id="sugar" 
                ar=""
                shadow-intensity="1"
                show-model-group
                style={{ width:"100%" ,height: "100%", background: "white",margin: "auto" ,display: "block" ,marginBottom: "60px" ,paddingBottom: "60px"}}
            ar-scale="fixed" show-option="" camera-controls="" ar-status="not-presenting" camera-down-lock="true"  camera-orbit="15deg 55deg auto" template="advanced" showDimension="true"
            product_id="${productsArray[index].productId}" company_id=${productsArray[index].companyId} exposure="1"  joint-show="" selectColor="#1EA9CD" qr="" is-panel-open="true" >
            </sugar-model-viewer>`;
		}, 10);

		popupRef.current.style.display = "flex";
		window.addEventListener('keydown', handleEscape);
	};

	const onClose = () => {
		popupRef.current.style.display = "none";
		sugarRef.current.innerHTML = "";
		window.removeEventListener('keydown', handleEscape);
	};

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<div className='base-component-text-container-wide'>
					<h2 className="component-header-large">{t("furniture")}</h2>
					<span className='base-component-span'>{t("furnitureSpan")}</span>
				</div>
				<div className="product-item-container">
					{productsArray.map((item, index) => (
						<div key={["furniture", index]} className="productItem" onClick={() => openPopup(index)}>
							<img src={item.url} className="productsImage" alt="Product" />
							<div className="productItemHeader" style={{ fontFamily: 'Nunito !important' }}>{item.header}</div>
						</div>
					))}
				</div>
				<div className="popupOverlay" ref={popupRef}>
					<div className="popup">
						<div className="closeButtons" onClick={onClose}>
							<img className="closeIcon" alt="an x" src={"/assets/img/productImages/popupVector2.svg"} />
						</div>
						<div className="sugarModelViewerContainer" ref={sugarRef}>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}