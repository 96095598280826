import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../BrandsComponent/Logo";
import { HeaderWithLine } from "../Common/HeaderWithLine";

const array = [
	"/assets/img/libraryBrands/01.webp",
	"/assets/img/libraryBrands/02.webp",
	"/assets/img/libraryBrands/03.webp",
	"/assets/img/libraryBrands/04.webp",
	"/assets/img/libraryBrands/05.webp",
	"/assets/img/libraryBrands/06.webp",
	"/assets/img/libraryBrands/07.webp",
	"/assets/img/libraryBrands/08.webp",
	"/assets/img/libraryBrands/09.webp",
	"/assets/img/libraryBrands/10.webp",
	"/assets/img/libraryBrands/11.webp",
	"/assets/img/libraryBrands/12.webp",
	"/assets/img/libraryBrands/13.webp",
	"/assets/img/libraryBrands/14.webp",
	"/assets/img/libraryBrands/15.webp",
	"/assets/img/libraryBrands/16.webp",
	"/assets/img/libraryBrands/18.webp",
	"/assets/img/libraryBrands/19.webp",
	"/assets/img/libraryBrands/20.webp",
	"/assets/img/libraryBrands/21.webp",
	"/assets/img/libraryBrands/22.webp",
	"/assets/img/libraryBrands/23.webp",
	"/assets/img/libraryBrands/24.webp",
	"/assets/img/libraryBrands/25.webp",
	"/assets/img/libraryBrands/26.webp",
	"/assets/img/libraryBrands/27.webp",
	"/assets/img/libraryBrands/28.webp",
	"/assets/img/libraryBrands/29.webp",
	"/assets/img/libraryBrands/30.webp",
	"/assets/img/libraryBrands/31.webp",
	"/assets/img/libraryBrands/32.webp",
	"/assets/img/libraryBrands/33.webp",
	"/assets/img/libraryBrands/34.webp",
];

export const LibraryBrands = () => {
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const list = array.map((brand, index) => (
		<Logo key={index} brandLogo={brand} classname="brand-logo-wrapper" />
	));

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		}
	});

	function generateColumns() {
		if (windowWidth >= 890) {
			const column1 = list.slice(0, Math.floor(list.length / 4));
			const column2 = list.slice(Math.floor(list.length / 4), Math.floor(list.length / 2));
			const column3 = list.slice(Math.floor(list.length / 2), Math.floor(list.length / 4) * 3);
			const column4 = list.slice(Math.floor(list.length / 4) * 3);
			return (
				<div className="brand-column-wrapper">
					<div className="brand-column">{column1}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column2}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column3}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column4}</div>
				</div>
			)
		} else if (windowWidth >= 600) {
			const column1 = list.slice(0, Math.floor(list.length / 3));
			const column2 = list.slice(Math.floor(list.length / 3), Math.floor(list.length / 1.5));
			const column3 = list.slice(Math.floor(list.length / 1.5));
			return (
				<div className="brand-column-wrapper">
					<div className="brand-column">{column1}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column2}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column3}</div>
				</div>
			)
		} else {
			const column1 = list.slice(0, Math.floor(list.length / 2));
			const column2 = list.slice(Math.floor(list.length / 2));
			return (
				<div className="brand-column-wrapper">
					<div className="brand-column">{column1}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column2}</div>
				</div>
			)
		}
	}

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<HeaderWithLine
					color="#1EA9CD"
					size="large"
					weight="700"
					title={t("brandsComponentHeader")}/>
				{generateColumns()}
			</div>
		</div>
	)
}