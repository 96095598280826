import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "./ReadyRooms.css";

export const ReadyRooms = () => {
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const [slideIndex, setSlideIndex] = useState(0);
	const [, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 800);
			setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const { t } = useTranslation();

	const array = [
		{
			url: "/assets/img/gabbaMilanoReadtRoom/mini.svg",
			bigImageUrl: "/assets/img/gabbaMilanoReadtRoom/main.svg",
			header: "Gabba - Milano Room . 101",
			description: t("readyRoomDescription101"),
			galleryImages: ["/assets/img/gabbaMilanoReadtRoom/4lu.svg", "/assets/img/gabbaMilanoReadtRoom/berjer.svg", "/assets/img/gabbaMilanoReadtRoom/ortaSehpa.svg", "/assets/img/gabbaMilanoReadtRoom/zigon.svg", "/assets/img/gabbaMilanoReadtRoom/sandalye.svg", "/assets/img/gabbaMilanoReadtRoom/yemekMasa.svg", "/assets/img/gabbaMilanoReadtRoom/puf.svg", "/assets/img/gabbaMilanoReadtRoom/ortaSehpa2.svg", "/assets/img/gabbaMilanoReadtRoom/tvUnite.svg", "/assets/img/gabbaMilanoReadtRoom/console.svg", "/assets/img/gabbaMilanoReadtRoom/dresuar.svg", "/assets/img/gabbaMilanoReadtRoom/dresuar2.svg", "/assets/img/gabbaMilanoReadtRoom/consoleMirror.svg", "/assets/img/gabbaMilanoReadtRoom/hali.svg", "/assets/img/gabbaMilanoReadtRoom/avonni1.svg", "/assets/img/gabbaMilanoReadtRoom/avonni2.svg", "/assets/img/gabbaMilanoReadtRoom/lambader.svg", "/assets/img/gabbaMilanoReadtRoom/cicek.svg", "/assets/img/gabbaMilanoReadtRoom/cicek2.svg", "/assets/img/gabbaMilanoReadtRoom/cicek3.svg", "/assets/img/gabbaMilanoReadtRoom/aksesuar.svg", "/assets/img/gabbaMilanoReadtRoom/aksesuar2.svg", "/assets/img/gabbaMilanoReadtRoom/aksesuar3.svg",],
			brands: ["Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Gabba", "Eko Halı", "Default", "Default", "Default", "Default", "Default", "Default", "Default", "Default", "Default",],
			gallerySpans: ["Milano 4'lü Kanepe", "Milano Berjer", "Milano Orta Sehpa", "Milano Zigon Sehpa", "Milano Sandalye", "Milano Yemek Mas.", "Milano Puf", "Milano Orta Sehpa", "Milano Tv Ünitesi", "Milano Konsol", "Milano Dresuar Ay.", "Milano Dresuar", "Milano Konsol Ay", "3300 light vizon", "av-1766-1e", "av-1766-4et", "Lambader", "Çiçek", "Çiçek 915", "Buket Çiçek 221", "Aksesuar", "Aksesuar 2", "Aksesuar 3"],
			artist: "@Nisa Nur Çakıcı"
		},
		{
			url: "/assets/img/lazzoniReadyRoom/mini.svg",
			bigImageUrl: "/assets/img/lazzoniReadyRoom/main.svg",
			header: "Lazzoni - Met Room . 102",
			description: t("readyRoomDescription102"),
			galleryImages: ["/assets/img/lazzoniReadyRoom/metCorner.png", "/assets/img/lazzoniReadyRoom/sideBoard.png", "/assets/img/lazzoniReadyRoom/ada360.png", "/assets/img/lazzoniReadyRoom/mera.png", "/assets/img/lazzoniReadyRoom/molitva.png", "/assets/img/lazzoniReadyRoom/misin.png", "/assets/img/lazzoniReadyRoom/ekoHali.png", "/assets/img/lazzoniReadyRoom/lambader.png", "/assets/img/lazzoniReadyRoom/default1.png", "/assets/img/lazzoniReadyRoom/default2.png",],
			brands: ["Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Eko Halı", "Default", "Default", "Default",],
			gallerySpans: ["Met Corner with se.", "Floresta Sideboard", "Ada 360 Swivel Ar.", "Mera Nesting Table", "Molitva Coffe Table", "Mısın Mirror", "3300 Light Vizon", "Lambader 24", "Çiçek 915", "Buket Çiçek 221"],
			artist: "@Nisa Nur Çakıcı"

		},
		{
			url: "/assets/img/lazzoniIkonReadyRoom/mini.svg",
			bigImageUrl: "/assets/img/lazzoniIkonReadyRoom/main.svg",
			header: "Lazzoni - Ikon Living Room .103",
			description: t("readyRoomDescription103"),
			galleryImages: [
				"/assets/img/lazzoniIkonReadyRoom/ikon.png",
				"/assets/img/lazzoniIkonReadyRoom/tetra.png",
				"/assets/img/lazzoniIkonReadyRoom/fortis.png",
				"/assets/img/lazzoniIkonReadyRoom/mons.png",
				"/assets/img/lazzoniIkonReadyRoom/ekoHali.png",
				"/assets/img/lazzoniIkonReadyRoom/avonniMl.png",
				"/assets/img/lazzoniIkonReadyRoom/avonniAv.png",
				"/assets/img/lazzoniIkonReadyRoom/abajur.png",
				"/assets/img/lazzoniIkonReadyRoom/cicek.png"
			],
			brands: ["Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Eko Halı", "Avonni", "Avonni", "Default", "Default"],
			gallerySpans: ["Ikon Dual upholste.", "Tetra sideboard", "Fortis Mirror", "Mons coffe table", "3300 dark vizon", "ml-64004-k39", "av-4288-bsy46", "Abajur", "Çiçek 915"],
			artist: "@Nisa Nur Çakıcı"
		},
		{
			url: "/assets/img/lazzoniMonyReadyRoom/mini.svg",
			bigImageUrl: "/assets/img/lazzoniMonyReadyRoom/main.svg",
			header: "Lazzoni - Mony Moon Room . 104",
			description: t("readyRoomDescription104"),
			galleryImages: ["/assets/img/lazzoniMonyReadyRoom/monny.png", "/assets/img/lazzoniMonyReadyRoom/pietra.png", "/assets/img/lazzoniMonyReadyRoom/console.png", "/assets/img/lazzoniMonyReadyRoom/mirror.png", "/assets/img/lazzoniMonyReadyRoom/hali.png", "/assets/img/lazzoniMonyReadyRoom/abajur.png", "/assets/img/lazzoniMonyReadyRoom/pampas.png", "/assets/img/lazzoniMonyReadyRoom/vazo.png", "/assets/img/lazzoniMonyReadyRoom/cicek.png",],
			brands: ["Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Eko Halı", "Default", "Default", "Default", "Default"],
			gallerySpans: ["Mony moon chaise.", "Pitra Coffee Table", "Pitra Console", "Gara Mirror", "3300 light vizon", "Abajur", "Pampas23", "Vazo", "Buket Cicek 221"],
			artist: "@Nisa Nur Çakıcı"
		},
		{
			url: "/assets/img/lazzoniBedReadyRoom/mini.svg",
			bigImageUrl: "/assets/img/lazzoniBedReadyRoom/main.svg",
			header: "Lazzoni - Harringbone bedroom . 105",
			description: t("readyRoomDescription105"),
			galleryImages: ["/assets/img/lazzoniBedReadyRoom/asto.png", "/assets/img/lazzoniBedReadyRoom/istanbul.png", "/assets/img/lazzoniBedReadyRoom/laire.png", "/assets/img/lazzoniBedReadyRoom/mons.png", "/assets/img/lazzoniBedReadyRoom/sofia.png", "/assets/img/lazzoniBedReadyRoom/simurg.png", "/assets/img/lazzoniBedReadyRoom/hali.png", "/assets/img/lazzoniBedReadyRoom/avonni.png", "/assets/img/lazzoniBedReadyRoom/avonni2.png", "/assets/img/lazzoniBedReadyRoom/sarkit.png",],
			brands: ["Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Lazzoni", "Simurg", "Eko Halı", "Avonni", "Avonni", "Default"],
			gallerySpans: ["Asto Lounge Chair", "Istanbul bed with.", "Laire Nightstand", "Mons round table", "Sofia Make-up Tab.", "Geçişler", "LGS 05 cream gra.", "Eksitme Kaplama", "Eksitme Kaplama", "Sarkıt aydınlatma"],
			artist: "@Nisa Nur Çakıcı"
		},
		{
			url: "/assets/img/iskandinavReadyRoom/iskandinavMini.svg",
			bigImageUrl: "/assets/img/iskandinavReadyRoom/iskandinavMain.svg",
			header: "Tepe Home Scandinavian Room . 106",
			description: t("readyRoomDescription106"),
			galleryImages: ["/assets/img/iskandinavReadyRoom/delwoodTv.svg", "/assets/img/iskandinavReadyRoom/delwoodMasa.svg", "/assets/img/iskandinavReadyRoom/bartonIkili.svg", "/assets/img/iskandinavReadyRoom/ekoHali.svg", "/assets/img/iskandinavReadyRoom/vazo.svg", "/assets/img/iskandinavReadyRoom/pampas.svg", "/assets/img/iskandinavReadyRoom/lambader.svg",],
			gallerySpans: ["Delwwod TV Ünitesi", "Delwood Masa", "Barton Büyük İkili", "AR 02 Grey", "Vazo 224", "Pampas23", "Lambader24"],
			brands: ["Tepe Home", "Tepe Home", "Tepe Home", "Eko Halı", "Default", "Default", "Default"],
			artist: "@Nisa Nur Çakıcı"
		},
		{
			url: "/assets/img/kysReadyRoom/mini.svg",
			bigImageUrl: "/assets/img/kysReadyRoom/main.svg",
			header: "KYS Colorful Living Room . 107",
			description: t("readyRoomDescription107"),
			galleryImages: ["/assets/img/kysReadyRoom/kolcakli.svg", "/assets/img/kysReadyRoom/tomtom.svg", "/assets/img/kysReadyRoom/masa.svg", "/assets/img/kysReadyRoom/sehpa.svg", "/assets/img/kysReadyRoom/tvUnite.svg", "/assets/img/kysReadyRoom/naturaPuf.svg", "/assets/img/kysReadyRoom/ekoHali1.svg", "/assets/img/kysReadyRoom/ekoHali2.svg", "/assets/img/kysReadyRoom/simurg1.svg", "/assets/img/kysReadyRoom/simurg2.svg", "/assets/img/kysReadyRoom/tv.svg", "/assets/img/kysReadyRoom/ayna.svg", "/assets/img/kysReadyRoom/default2.svg", "/assets/img/kysReadyRoom/default3.svg", "/assets/img/kysReadyRoom/default4.svg", "/assets/img/kysReadyRoom/default5.svg", "/assets/img/kysReadyRoom/default6.svg", "/assets/img/kysReadyRoom/default7.svg", "/assets/img/kysReadyRoom/default8.svg", "/assets/img/kysReadyRoom/default9.svg", "/assets/img/kysReadyRoom/default10.svg",],
			brands: ["Kys", "Kys", "Kys", "Kys", "Kys", "Kys", "Eko Halı", "Eko Halı", "Simurg", "Simurg", "Toshiba", "İstikbal", "Default", "Default", "Default", "Default", "Default", "Default", "Default", "Default", "Default",],
			gallerySpans: ["Sofaline 2p kolçaklı", "Tomtom kanepe", "Bridge masa", "Aks sehpa", "Lips Tv ünitesi", "Natura puf üzeri se.", "BRK 01 Cream Silv.", "BRK 10 Natural lv.", "Kırmızı", "Kuşlar", '55xa9d63dt 55"13', "Vista makyaj ayna", "Vazo 2026", "Vazo 1422", "Vazo 1120", "Vazo 720", "Vazo 519", "Şömine 325", "Çiçek 915", "Çiçek 514", "Buket çiçek 221"],
			artist: "@Nisa Nur Çakıcı (3D Artist)"
		},
		{
			url: "/assets/img/chaletReadyRoom/chaletMini.png",
			bigImageUrl: "/assets/img/chaletReadyRoom/chaletMain.svg",
			header: "Chalet Living Room . 108",
			description: t("readyRoomDescription108"),
			galleryImages: ["/assets/img/chaletReadyRoom/denverTekli.png", "/assets/img/chaletReadyRoom/gloriaSandalye.png", "/assets/img/chaletReadyRoom/gloriaYemekMasa.png", "/assets/img/chaletReadyRoom/lisalaKoltuk.png", "/assets/img/chaletReadyRoom/gloriaKonsol.png", "/assets/img/chaletReadyRoom/gaydaAyna.png", "/assets/img/chaletReadyRoom/ekoHalı.png", "/assets/img/chaletReadyRoom/somine325.png", "/assets/img/chaletReadyRoom/buketCicek.png", "/assets/img/chaletReadyRoom/sarkit.png",],
			gallerySpans: ["Denver Tekli Koltuk", "Gloria Sandalye", "Gloria Yemek Masa", "Lisa Koltuk", "Gloria Konsol", "Gayda Ayna", "Pu alt 04", "Şömine 325", "Buket Çicek 221", "Sarkıt 637"],
			brands: ["Tepe Home", "Tepe Home", "Tepe Home", "Tepe Home", "Tepe Home", "Tepe Home", "Eko Halı", "Default", "Default", "Default"],
			artist: "@Nisa Nur Çakıcı"
		},

		{
			url: "/assets/img/tiliaReadyRoom/mini.svg",
			bigImageUrl: "/assets/img/tiliaReadyRoom/tiliaMain.svg",
			header: "Tilia Office Kitchen . 109",
			description: t("readyRoomDescription109"),
			galleryImages: ["/assets/img/tiliaReadyRoom/eos.png", "/assets/img/tiliaReadyRoom/gozoBar.png", "/assets/img/tiliaReadyRoom/table.png", "/assets/img/tiliaReadyRoom/Avonni.png", "/assets/img/tiliaReadyRoom/kahve.png", "/assets/img/tiliaReadyRoom/kahve2.png",],
			brands: ["Tilia", "Tilia", "Tilia", "Avonni", "Vestel", "Vestel"],
			gallerySpans: ["Eos_o", "Gozo bar sandalye", "Rectangle table", "Bakır kaplama apli.", "Retro kahve makinası", "S910 kahve makinası"],
			artist: "@Nisa Nur Çakıcı"
		},


	];

	const [selectedItem, setSelectedItem] = useState(array[0]);

	const handleItemClick = (item) => {
		setSelectedItem(item);
		setSlideIndex(0);
		const prevSelectedItem = document.querySelector(".productItemImage.active");
		if (prevSelectedItem)
			prevSelectedItem.classList.remove("active");

		const selectedItem = document.querySelector(
			`.productItemImage[src="${item.url}"]`
		);

		if (selectedItem)
			selectedItem.classList.add("active");
		const element = document.querySelector(".readyRoomsComponentContainer");
		if (element)
			element.scrollIntoView({ behavior: 'smooth' });
	};

	const swiperSettings = {
		className: "swiperReadyRooms",
		modules: [Navigation, Pagination],
		slidesPerView: "auto",
		navigation: {
			prevEl: prevRef.current,
			nextEl: nextRef.current,
		},
	}

	const onClickNext = () => {
		setSlideIndex(slideIndex + 1);
	}

	const onClickPrev = () => {
		setSlideIndex(slideIndex - 1);
	}

	return (
		<div className="base-component component-padding column">
			<div className="base-component-text-container-wide">
				<h2 className="component-header-normal">{t("functionElement13Header")}</h2>
				<span className="base-component-span">{t("functionElement13Span")} </span>
			</div>
			<div className="readyRoomsDescription">
				<h2 className="readyRoomsDescriptionHeader">{selectedItem.header}</h2>
				<img className="readyRoomBigImage" width="100%" alt="ready room" src={selectedItem.bigImageUrl} />
				<div className={"readyRoomsDescriptionGalleryContainer " + (slideIndex > 0 ? " active" : "") + (selectedItem.galleryImages.length > 9 ? " right" : "")} >
					<div className="readyRoomsDescriptionGallery">
						<div style={{
							width: isMobile ? "111%" : "103%",
							position: "absolute",
							top: "35%",
							left: "-20px",
							display: selectedItem.galleryImages.length > 7 ? "flex" : "none",
							justifyContent: slideIndex ? "space-between" : "flex-end",
						}}>
							<img alt='previous arrow'
								ref={prevRef}
								src="/assets/img/readyRoomsImages/readyRoomsPrev.svg"
								onClick={onClickPrev}
								style={{
									display: slideIndex ? "flex" : "none",
									width: "44px",
									height: "44px",
									cursor: "pointer",
									zIndex: "3"
								}} />
							<img alt='next arrow'
								ref={nextRef}
								src="/assets/img/readyRoomsImages/readyRoomsNext.svg"
								onClick={onClickNext}
								style={{
									width: "44px",
									height: "44px",
									cursor: "pointer",
									zIndex: "3"
								}} />
						</div>
						<Swiper {...swiperSettings} >
							{selectedItem.galleryImages.map((item, index) => (
								<SwiperSlide key={"readyRoomSlider" + index} className="readyRoomsDescriptionGalleryItem" >
									<div className="readyRoomBackroundMini">
										<img className="readyRoomsDescriptionGalleryImg" alt="ready room icon" src={item}></img>
									</div>
									<span className="readyRoomsDescriptionGallerySpan">{selectedItem.brands[index]}</span>
									<span className="readyRoomDescriptionProductName"> {selectedItem.gallerySpans[index]} </span>
								</SwiperSlide>
							))}
						</Swiper>

					</div>
				</div>
				<div className="readyRoomItemDescriptionSpans">
					<h2 className="productItemHeader left" style={{ width: "100%" }}>{selectedItem.header}</h2>
					<span className="productItemArtistName">{selectedItem.artist + " (" + t('interiorArchitect') + ")"}</span>
					<span className="readyRoomItemDescriptionSpan">{selectedItem.description}</span>
				</div>
			</div>
			<div className="readyRoomsContainer">
				{array.map((item, index) => (
					<div key={index}
						className={`readyRoomItem ${item.url === selectedItem.url ? "active" : ""}`}
						onClick={() => handleItemClick(item)}>
						<img className={`productItemImage ${item.url === selectedItem.url ? "active" : ""}`}
							src={item.url}
							alt="ready room"
							style={{
								border: item === selectedItem && "2px solid #1EA9CD"
							}} />
						<div className={selectedItem === item ? "activeNameBlue" : ""}
							style={{
								display: "flex",
								flexDirection: "column",
								position: "relative"
							}}>
							<h2 className="productItemHeader"
								style={{
									color: item === selectedItem && "#1EA9CD",
									alignSelf: "start",
									textAlign: "start",
									width: "85%"
								}}>
								{item.header}
							</h2>
							<span className="productItemArtist">
								{item.artist}
							</span>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}