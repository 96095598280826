import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderWithLine } from "../Common/HeaderWithLine";
import BrandArrays from "./BrandArrays";
import "./BrandsComponent.css";
import { Logo } from "./Logo";

export const Brands = () => {
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const list = BrandArrays.map((brand, index) => (
		<Logo key={index} brandLogo={brand} classname="brand-logo-wrapper" />
	));

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		}
	});

	function generateColumns() {
		if (windowWidth > 890) {
			const column1 = list.slice(0, Math.ceil(list.length / 4));
			const column2 = list.slice(Math.ceil(list.length / 4), Math.ceil(list.length / 2));
			const column3 = list.slice(Math.ceil(list.length / 2), Math.ceil(list.length / 4) * 3);
			const column4 = list.slice(Math.ceil(list.length / 4) * 3);
			return (
				<div className="brand-column-wrapper">
					<div className="brand-column">{column1}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column2}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column3}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column4}</div>
				</div>
			)
		} else if (windowWidth > 600) {
			const column1 = list.slice(0, Math.ceil(list.length / 3) - 1);
			const column2 = list.slice(Math.ceil(list.length / 3) - 1, Math.ceil(list.length / 1.5));
			const column3 = list.slice(Math.ceil(list.length / 1.5));
			return (
				<div className="brand-column-wrapper">
					<div className="brand-column">{column1}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column2}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column3}</div>
				</div>
			)
		} else {
			const column1 = list.slice(0, Math.ceil(list.length / 2));
			const column2 = list.slice(Math.ceil(list.length / 2));
			return (
				<div className="brand-column-wrapper">
					<div className="brand-column">{column1}</div>
					<div className="brand-column-divider"></div>
					<div className="brand-column">{column2}</div>
				</div>
			)
		}
	}

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<HeaderWithLine
					color="#1EA9CD"
					size="large"
					weight="700"
					title={t("brandsComponentHeader")} />
				{generateColumns()}
			</div>
		</div>
	)
}