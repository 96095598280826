var _Attr = { className: '', style: {}, };

var _Container = {
	attr: _Attr, textContainer: {
		attr: {
			..._Attr,
			className: 'text-container',
		}
	}, mediaContainer: {
		attr: {
			..._Attr,
			className: 'media-container',
		},
	}
};

var _Header = { attr: _Attr, };

var _Span = { attr: _Attr, };

var _List = {
	ulAttr: { attr: _Attr },
	liAttr: { attr: _Attr },
	items: [],
};

var _Image = { attr: { ..._Attr, src: '' } };

var _Video = {
	attr: {
		..._Attr,
		url: '',
		style: {},
		wrapper: "div",
		light: '',
		controls: true,
		loop: true,
		muted: true,
		config: { file: { attributes: { controlsList: {} } } },
	},
};

var _Wrapper = { attr: _Attr, };

class _StyledDiv {
	constructor() {
		this.wrapper = _Wrapper;
		this.container = _Container;
		this.header = _Header;
		this.span = _Span;
		this.list = _List;
		this.image = _Image;
		this.video = _Video;
	}
};

export { _StyledDiv };
