import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import lottieFile from "../../animations/tick.json";
import { useApi } from "../../api/useApi";
import "./ResetPasswordComponent.css";

export const ResetPasswordComponent = (props) => {
	const { t } = useTranslation();
	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");
	const [captchaLogin, setCaptchaLogin] = useState(false);
	const api = useApi();
	const navigate = useNavigate();
	const [showResponse, setShowResponse] = useState(false);
	const [response, setResponse] = useState(false);

	useEffect(() => {
		const footer = document.querySelector(".footerMainContainer");
		footer.style.display = "none";
	}, [])

	const handleResetClick = async () => {
		if (password1 === password2 && password1 !== "") {
			const formData = new FormData();
			formData.append("password", new Blob([password1]));
			formData.append("id", new Blob([props.id]));
			try {
				const response = await api.resetPassword(formData);
				if (response.status === HttpStatusCode.Ok) {
					setResponse(true);
					setShowResponse(true);
				}
			} catch (e) {
				setResponse(false);
				setShowResponse(true);
				// console.log(e);
			}
		}
	}

	return (
		<div style={{ marginTop: "90px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "60%", gap: "20px" }}>
			<span>reset password for user {props.id}</span>
			<div className="authCreateAccountInputs">
				<div className="resetPasswordInputContainer">
					<img alt="envelope" className="authCreateAccountInputImage" src="/assets/img/authOptions/mail.svg" />
					<input className="authCreateAccountInput" value={password1} onChange={(e) => setPassword1(e.target.value)} placeholder={"Password"}>
					</input>
				</div>
				<div className="resetPasswordInputContainer">
					<img alt="envelope" className="authCreateAccountInputImage" src="/assets/img/authOptions/mail.svg" />
					<input className="authCreateAccountInput" value={password2} onChange={(e) => setPassword2(e.target.value)} placeholder={"Password"}>
					</input>
				</div>
				<ReCAPTCHA
					sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
					className="reCaptcha"
					onChange={val => setCaptchaLogin(val)}
				/>
				<div className="authMailButton" style={{ background: captchaLogin ? "#1EA9CD" : "#495057", cursor: captchaLogin ? "pointer" : "default" }} onClick={handleResetClick}>
					<span className="authMailText"> {t("continue")} </span>
				</div>
			</div>

			<div className="alertOverlay" style={{ display: !showResponse && "none" }}>
				<div className="authCreateAccountContainer" style={{ display: showResponse ? "flex" : "none" }}>
					<img alt="close icon" src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { navigate("/") }} />
					{
						response ?
							<>
								<div className="affirmativeThanksMessage">
									{t("thanks")}
								</div>
								<Lottie
									loop
									animationData={lottieFile}
									play
									style={{ width: "50%" }}
								/>
								<div className="affirmativeMessage">
									Şifreniz başarıyla sıfırlanmıştır!!!
								</div>
								<div className="affirmativeMail">

								</div>
							</>

							:
							<>
								<img alt="error" src="/assets/img/errorImage.svg" />
								<div className="rejectMessageTitle">
									{t("oops")}
								</div>
								<div className="rejectMessageSpan">
									Beklenmedik bir hata oluştu..
								</div>
								<div className="authMailButton" onClick={() => { }}>
									<span className="authMailText">
										Bir hata oluştu....
									</span>
								</div>
							</>

					}

				</div>
			</div>


		</div>
	)

}