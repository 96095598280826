import React from 'react';
import { Brands } from "../../components/BrandsComponent/BrandsComponent";
import { DownloadApp } from '../../components/DownloadAppComponent/DownloadApp';
import { FinalRenders } from '../../components/FinalRenders/FinalRenders';
import { MainVideoComponent } from '../../components/MainVideoComponent/MainVideoComponent';
import { HomeBanner } from '../../components/PageBanners/HomeBanner';
import { WhatIsRapid } from '../../components/WhatIsRapidComponent/WhatIsRapid';
import { WhatsNew } from '../../components/WhatsNew/WhatsNew';
import { WhyRapid } from '../../components/WhyRapidComponent/WhyRapid';
import { WhyRapidSwiper } from '../../components/WhyRapidSwiperComponent/WhyRapidSwiper';

export const HomePage = props => {

	return (
		<div className={`page-margin`}>
			<HomeBanner />
			<DownloadApp />
			<WhatIsRapid />
			<WhyRapid />
			<div style={{height: "0", border: "1px inset #1EA9CD"}}></div>
			<WhyRapidSwiper />
			<MainVideoComponent />
			<FinalRenders />
			<Brands />
			<WhatsNew />
		</div>
	);
}