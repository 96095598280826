import { useEffect, useState } from "react";
import LegalNoticeComponent from "../../components/LegalNotice/LegalNoticeComponent";
import { privacyPolicyContent } from "../../components/LegalNotice/PrivacyPolicyContent";

export default function PrivacyPolicyPage() {
	const [isTablet, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 800);
			setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);

		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className={`${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} page-margin legalNoticePage`}>
			<LegalNoticeComponent notice={privacyPolicyContent}></LegalNoticeComponent>
		</div>
	);
}