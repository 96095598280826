import './SwiperShading.css';
export const SwiperShading = ({ children, ...props }) => {
	return (
		<div className="swiper-shading-container" style={props.style}>
			<div className="swiper-shading-left" style={{
				background: `linear-gradient(90deg, rgba(${props.color}, 1) 0, rgba(${props.color}, 0) 100%)`
			}} />
			{children}
			<div className="swiper-shading-right" style={{
				background: `linear-gradient(90deg, rgba(${props.color}, 0) 0, rgba(${props.color}, 1) 100%)`
			}} />
		</div>
	);
}