import { useTranslation } from "react-i18next";
import './Contact.css';
import { ContactInput } from './ContactInput';

export const Contact = () => {
	const { t } = useTranslation();
	return (
		<div className="component-wrapper" style={{
			backgroundImage: 'URL("/assets/img/contactImages/contactBackground.svg")',
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
		}}>
			<div className='contactComponent'>
				<div className='contactElements'>
					<div className='contactSpans'>
						<p className='contactHeader'>{t("contactHeader")}</p>
						<p className='contactSpan'>{t("contactSpan")}</p>
					</div>
					<div className='contactInformations'>
						<div className='contactWay'>
							<h2 className='contactWayHeader'>{t('contactSalesTeam')}</h2>
							<div className='contactWaySpans'>
								<img alt='envelope' src={'/assets/img/contactImages/mail.svg'}></img>
								<span className='contactMail'>{t('contactInfoSalesTeam')}</span>
							</div>
						</div>
						{/* <div className='contactWay'>
                        <h2 className='contactWayHeader'>{ t('contactMarketingTeam') }</h2>
                        <div className='contactWaySpans'>
                            <img alt='envelope' src={'/assets/img/contactImages/mail.svg'}></img>
                            <span className='contactMail'>{ t('contactInfoMarketingTeam') }</span>
                        </div>
                    </div> */}
						<div className='contactWay'>
							<h2 className='contactWayHeader'>{t('contactCustomerService')}</h2>
							<div className='contactWaySpans'>
								<img alt='phone' src={'/assets/img/contactImages/phone.svg'}></img>
								<span className='contactPhone'>{t('contactInfoCustomerService')}</span>
							</div>
						</div>
					</div>
				</div>
				<ContactInput></ContactInput>
			</div>
		</div>
	);
}