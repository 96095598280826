import { createContext, useMemo, useState } from "react";
// import { useSearchParams } from "react-router-dom";
import { useApi } from "../api/useApi";
import { useStringLocalStorage } from "../storage/useStorage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const api = useApi();

	// const [searchParams, setSearchParams] = useSearchParams();


	const [authorisationCode, setAuthorisationCode] = useStringLocalStorage(
		"authorisationCode",
		null
	);

	const [show, setShow] = useState(false);
	const [onSuccess, setOnSuccess] = useState(() => { });
	const [onError, setOnError] = useState(() => { });


	// // call this function to sign out logged in user
	// const logout = () => {
	//     setAuthorisationCode(null);
	//     //logout func
	// };

	const value = useMemo(() => {
		const runAuthorization = (onSuccess, onError) => {
			setShow(true);
			setOnSuccess(() => { return onSuccess });
			setOnError(() => { return onError });
		};

		const setShowFalse = () => {
			setShow(false);
		}

		const setShowTrue = () => {
			setShow(true);
		}

		const isAuthorized = () => {
			return !(!authorisationCode || authorisationCode === "" || authorisationCode === "null")
		}

		const login = async (data) => {
			setAuthorisationCode(null);
			let authorizationCode = await api.loginUser(data);
			setAuthorisationCode(authorizationCode.data.split(" ")[0]);
			return authorisationCode;
		};

		const create = async (data) => {
			return await api.createUser(data);
		}
		return {
			authorisationCode: authorisationCode,
			login: login,
			show: show,
			onSuccess: onSuccess,
			runAuthorization: runAuthorization,
			isAuthorized: isAuthorized,
			setShowFalse: setShowFalse,
			onError: onError,
			create: create,
			setShowTrue: setShowTrue,
		}
	}, [api, show, onSuccess, onError, authorisationCode, setAuthorisationCode]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
