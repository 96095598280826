const definitions = {
    title: "Tanımlar",
    content: [
        "a. \"Platform\": Şirket tarafından sunulan [https://sugartech.io/] ve [https://rapidapp.sugartech.io/] internet sitesi ve site dahilinde indirilebilir olan uygulamalar ve tüm erişim özellikleri.",
        "b. \"Kullanıcı\": Platforma erişen ve hizmetleri kullanan gerçek veya tüzel kişi.",
        "c. \"Hizmetler\": Platform aracılığıyla sunulan tüm ürünler, hizmetler, içerikler ve özellikler.",
    ]
};

const legalNoticeManifest = {
    paragraphs: [
        "Bu sözleşme, Sugar Technology Yazılım A.Ş. (\"Şirket\") ve (\"Kullanıcı\") arasında [https://sugartech.io/] ve [https://rapidapp.sugartech.io/] (\"Platform\") üzerinden sağlanan hizmetlerin gizliliğine ilişkin koşulları belirlemektedir.",
        "Sugar Technology Yazılım A.Ş. tarafından sağlanan Platform’un özell(k ve hizmetlerini kullanmadan önce dikkatli bir şekilde okumanızı ve tam olarak anlamanızı öneririz. İlgili sözleşmenin şartlarıyla ilgili herhangi bir endişeniz varsa lütfen hello@sugartech.io adresinden SugAR Tech destek ekibine e-posta gönderin.",
        "Sözleşmede bulunan herhangi bir şartı kabul etmiyorsanız, lütfen Platform’u kullanmayı bırakın. Zira Platform’u kullanmanız, bu Sözleşme’de yer alan tüm içeriğin onaylandığı ve tamamen kabul edildiğı anlamına gelecektir."
    ]
};

export { definitions, legalNoticeManifest };