import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useOnScreen } from "../Common/ScreenUtil";

export const Curtain = () => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const [playing, setPlaying] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) {
			setPlaying(true);
		} else {
			setPlaying(false);
		}
	}, [onScreen]);

	return (
		<div className="base-component component-padding column">
			<div className="base-component-text-container-wide">
				<h2 className="component-header-normal">{t("functionElement5Header")}</h2>
				<span className="base-component-span">{t("functionElement5Span")}  </span>
			</div>
			<ReactPlayer
				ref={ref}
				url='https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugartechvideos/perde.mp4'
				width="100%"
				height="100%"
				controls={true}
				loop={true}
				muted={true}
				config={{ file: { attributes: { controlsList: 'nodownload' } } }}
				playing={playing}
			/>
		</div>
	)
}