export const FeaturesArray = [
	{
		container: {textContainer: {attr: { style: { width: "min(50vw, 720px)"} },},},
		header: { value: "whatRRTitle", },
		span: { value: "whatIsRapidCompanySpans", },
		list: {
			ulAttr: { attr: { style: { listStyleImage: "url('/assets/img/whatIsRapidImages/circle.svg')", }, }, },
			items: ["whatIsRapidCompanySpan1", "whatIsRapidCompanySpan2", "whatIsRapidCompanySpan3"]
		},
		image: { attr: { alt: "a tablet, has a website, which contains renders, open", src: "/assets/img/companiesPage/w1.webp", } },
	},
	{
		header: { value: "specialAppHeader", },
		span: { value: "specialAppSpan", },
		image: { attr: { alt: "downloadable app", src: "/assets/img/companiesPage/s3.webp", } },
	},
	{
		header: { value: "architecturalTitle", },
		span: { value: "architecturalSpan", },
		image: { attr: { alt: "architectural consultant", src: "/assets/img/companiesPage/a1.webp", } },
	},
	{
		header: { value: "kioskTitle", },
		span: { value: "kioskSpan", },
		image: { attr: { alt: "kiosk", src: "/assets/img/companiesPage/k1.webp", } },
	},
	{
		header: { value: "dataTitle", },
		span: { value: "dataSpan", },
		image: { attr: { alt: "some furnitures with a drawing tablet", src: "/assets/img/companiesPage/s1.webp", } },
	},
	{
		header: { value: "graph2Title", },
		span: { value: "graph2Span", },
		image: { attr: { alt: "graph", src: "/assets/img/companiesPage/g2.webp", } },
	},
	{
		header: { value: "graph1Title", },
		span: { value: "graph1Span", },
		image: { attr: { alt: "graph", src: "/assets/img/companiesPage/g1.webp", } },
	},
	{
		header: { value: "showBrandsHeader", },
		span: { value: "showBrandsSpan", },
		image: { attr: { alt: "a cargo container with boxes in it, a globe behind", src: "/assets/img/companiesPage/s2.webp", } },
	},
	{
		header: { value: "companiesSalesHeader", },
		span: { value: "companiesSalesSpan", },
		image: { attr: { alt: "graph", src: "/assets/img/companiesPage/g3.webp", } },
	},
	{
		header: { value: "betterExplainHeader", },
		span: { value: "betterExplainSpan", },
		image: { attr: { alt: "usage visual", src: "/assets/img/companiesPage/e1.webp", } },
	},
	{
		header: { value: "storeAdFactorySolutionHeader", },
		span: { value: "storeAdFactorySolutionSpan", },
		image: { attr: { alt: "configuration", src: "/assets/img/companiesPage/c1.webp", } },
	},
];