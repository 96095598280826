const FinalRenderArrays = [
	{
		id: 0,
		title: "Asır Group 12",
		alt: "",
		src: "/assets/img/finalRenders/final_render_02.webp",
	},
	{
		id: 1,
		title: "Asır Group 15",
		alt: "",
		src: "/assets/img/finalRenders/final_render_03.webp",
	},
	{
		id: 2,
		title: "Asır Group 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_04.webp",
	},
	{
		id: 3,
		title: "Asır Group 8",
		alt: "",
		src: "/assets/img/finalRenders/final_render_05.webp",
	},
	{
		id: 4,
		title: "Asır Group 6",
		alt: "",
		src: "/assets/img/finalRenders/final_render_06.webp",
	},
	{
		id: 5,
		title: "Bella Maison 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_08.webp",
	},
	{
		id: 6,
		title: "Bella Maison 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_09.webp",
	},
	{
		id: 7,
		title: "Bella Maison 3",
		alt: "",
		src: "/assets/img/finalRenders/final_render_10.webp",
	},
	{
		id: 8,
		title: "Bianos 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_11.webp",
	},
	{
		id: 9,
		title: "Bianos 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_12.webp",
	},
	{
		id: 10,
		title: "Bianos 3",
		alt: "",
		src: "/assets/img/finalRenders/final_render_13.webp",
	},
	{
		id: 11,
		title: "Creavit 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_14.webp",
	},
	{
		id: 12,
		title: "Creavit 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_15.webp",
	},
	{
		id: 13,
		title: "Creavit 3",
		alt: "",
		src: "/assets/img/finalRenders/final_render_16.webp",
	},
	{
		id: 14,
		title: "Creavit 4",
		alt: "",
		src: "/assets/img/finalRenders/final_render_17.webp",
	},
	{
		id: 15,
		title: "Gabba Milano 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_18.webp",
	},
	{
		id: 16,
		title: "Gabba Milano 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_19.webp",
	},
	{
		id: 17,
		title: "Lazzoni 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_20.webp",
	},
	{
		id: 18,
		title: "Lazzoni Ikon 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_21.webp",
	},
	{
		id: 19,
		title: "Lazzoni Ikon 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_22.webp",
	},
	{
		id: 20,
		title: "Lazzoni Mony",
		alt: "",
		src: "/assets/img/finalRenders/final_render_23.webp",
	},
	{
		id: 21,
		title: "Lazzoni Met 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_24.webp",
	},
	{
		id: 22,
		title: "Lazzoni Met 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_25.webp",
	},
	{
		id: 23,
		title: "Lazzoni Met 3",
		alt: "",
		src: "/assets/img/finalRenders/final_render_26.webp",
	},
	{
		id: 24,
		title: "Lazzoni Mix 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_27.webp",
	},
	{
		id: 25,
		title: "Lazzoni Mix 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_28.webp",
	},
	{
		id: 26,
		title: "Lazzoni",
		alt: "",
		src: "/assets/img/finalRenders/final_render_29.webp",
	},
	{
		id: 27,
		title: "Setline 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_30.webp",
	},
	{
		id: 28,
		title: "Setline 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_31.webp",
	},
	{
		id: 29,
		title: "TepeHome 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_32.webp",
	},
	{
		id: 30,
		title: "TepeHome 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_33.webp",
	},
	{
		id: 31,
		title: "The Bed Co Uk 1",
		alt: "",
		src: "/assets/img/finalRenders/final_render_34.webp",
	},
	{
		id: 32,
		title: "The Bed Co Uk 2",
		alt: "",
		src: "/assets/img/finalRenders/final_render_35.webp",
	},
	{
		id: 33,
		title: "The Bed Co Uk 3",
		alt: "",
		src: "/assets/img/finalRenders/final_render_36.webp",
	},
	{
		id: 34,
		title: "The Bed Co Uk 3",
		alt: "",
		src: "/assets/img/finalRenders/final_render_37.webp",
	}
]

export default FinalRenderArrays;