import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResetPasswordComponent } from '../../components/ResetPasswordComponent/ResetPasswordComponent';


export const ResetPasswordPage = props => {
	const [isTablet, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const { id } = useParams();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 800);
			setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className={`${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} page-margin resetPage`}
			style={{
				overflowX: "hidden",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}>
			<ResetPasswordComponent id={id}></ResetPasswordComponent>
		</div>
	);
}