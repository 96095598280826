// import { useTranslation } from "react-i18next";
import "./LittleDownloadComponent.css";

export const LittleDownload = () => {
	// const { t } = useTranslation();

	return (""
		// <div className="littleDownloadComponent" style={{ backgroundImage: 'URL("/assets/img/littleDownloadImages/customers.jpg")' }}>
		// 	<div className="littleDownloadContainer">
		// 		<div className="littleDownloadSpans">
		// 			<span className="littleDownloadSpan">{t("littleDownloadSpan")}</span>
		// 		</div>
		// 		<div className="littleDownloadButtons">
		// 			<a className="button1" href="contact">{t("signUpText")}</a>
		// 			<a className="button2" href="pricingOptions">{t("freeTrial")}</a>
		// 		</div>
		// 	</div>
		// </div>
	)
}