import React from "react";
import ReactPlayer from "react-player";

const renderHeader = (header) => {
	if (header.value) {
		return (
			<h2
				{...header.attr}
				className={`styled-div-header ${header.attr.className}`}>
				{header.value}
			</h2>
		);
	}
	return null;
}

const renderSpan = (span) => {
	if (span.value) {
		return (
			<span
				{...span.attr}
				className={`styled-div-span ${span.attr.className}`}>
				{span.value}
			</span>
		);
	}
	return null;
}

const renderList = (list) => {
	if (list && list.items && list.items.length > 0) {
		return (
			<ul
				{...list.ulAttr.attr}
				className={`styled-div-list ${list.ulAttr.attr.className}`}>
				{
					list.items.map((item, index) => {
						return (
							<li
								key={index}
								{...list.liAttr.attr}
								className={`styled-div-list-item ${list.liAttr.attr.className}`}>
								{item}
							</li>
						);
					})}
			</ul>
		);
	}
	return null;
};

const renderImage = (image) => {
	if (image && image.attr && image.attr.src) {
		return (
			<img
				alt="no alt text provided"
				{...image.attr}
				className={`styled-div-image ${image.attr.className}`}

			/>
		);
	}
	return null;
};

const renderVideo = (video) => {
	if (video && video.attr && video.attr.url) {
		return (
			<ReactPlayer
				{...video.attr}
				className={`styled-div-video ${video.attr.className}`} />
		);
	}
	return null;
};

const renderWrapper = (result, wrapper) => {
	return (
		<div
			{...wrapper.attr}
			className={`styled-div-wrapper ${wrapper.attr.className}`}>
			{result}
		</div>
	);
};

function merge(obj1, obj2) {
	return {
		...obj1,
		...obj2,
		wrapper: {
			...obj1.wrapper,
			...obj2.wrapper,
			attr: {
				...obj1.wrapper?.attr,
				...obj2.wrapper?.attr,
				className: `${obj1.wrapper?.attr?.className ?? ''} ${obj2.wrapper?.attr?.className ?? ''}`,
				style: {
					...obj1.wrapper?.attr?.style,
					...obj2.wrapper?.attr?.style,
				},
			},
		},
		container: {
			...obj1.container,
			...obj2.container,
			attr: {
				...obj1.container?.attr,
				...obj2.container?.attr,
				className: `${obj1.container?.attr?.className ?? ''} ${obj2.container?.attr?.className ?? ''}`,
				style: {
					...obj1.container?.attr?.style,
					...obj2.container?.attr?.style,
				},
			},
			textContainer: {
				...obj1.container?.textContainer,
				...obj2.container?.textContainer,
				attr: {
					...obj1.container?.textContainer?.attr,
					...obj2.container?.textContainer?.attr,
					className: `${obj1.container?.textContainer?.attr?.className ?? ''} ${obj2.container?.textContainer?.attr?.className ?? ''}`,
					style: {
						...obj1.container?.textContainer?.attr?.style,
						...obj2.container?.textContainer?.attr?.style,
					},
				},
			},
			mediaContainer: {
				...obj1.container?.mediaContainer,
				...obj2.container?.mediaContainer,
				attr: {
					...obj1.container?.mediaContainer?.attr,
					...obj2.container?.mediaContainer?.attr,
					className: `${obj1.container?.mediaContainer?.attr?.className ?? ''} ${obj2.container?.mediaContainer?.attr?.className ?? ''}`,
					style: {
						...obj1.container?.mediaContainer?.attr?.style,
						...obj2.container?.mediaContainer?.attr?.style,
					},
				},
			},
		},
		header: {
			...obj1.header,
			...obj2.header,
			attr: {
				...obj1.header?.attr,
				...obj2.header?.attr,
				className: `${obj1.header?.attr?.className ?? ''} ${obj2.header?.attr?.className ?? ''}`,
				style: {
					...obj1.header?.attr?.style,
					...obj2.header?.attr?.style,
				},
			},
		},
		span: {
			...obj1.span,
			...obj2.span,
			attr: {
				...obj1.span?.attr,
				...obj2.span?.attr,
				className: `${obj1.span?.attr?.className ?? ''} ${obj2.span?.attr?.className ?? ''}`,
				style: {
					...obj1.span?.attr?.style,
					...obj2.span?.attr?.style,
				},
			},
		},
		list: {
			...obj1.list,
			...obj2.list,
			ulAttr: {
				...obj1.list?.ulAttr,
				...obj2.list?.ulAttr,
				attr: {
					...obj1.list?.ulAttr?.attr,
					...obj2.list?.ulAttr?.attr,
					className: `${obj1.list?.ulAttr?.attr?.className ?? ''} ${obj2.list?.ulAttr?.attr?.className ?? ''}`,
					style: {
						...obj1.list?.ulAttr?.attr?.style,
						...obj2.list?.ulAttr?.attr?.style,
					},
				},
			},
			liAttr: {
				...obj1.list?.liAttr,
				...obj2.list?.liAttr,
				attr: {
					...obj1.list?.liAttr?.attr,
					...obj2.list?.liAttr?.attr,
					className: `${obj1.list?.liAttr?.attr?.className ?? ''} ${obj2.list?.liAttr?.attr?.className ?? ''}`,
					style: {
						...obj1.list?.liAttr?.attr?.style,
						...obj2.list?.liAttr?.attr?.style,
					},
				},
			},
			items: [
				...(obj2.list?.items ?? []),
			],
		},
		image: {
			...obj1.image,
			...obj2.image,
			attr: {
				...obj1.image?.attr,
				...obj2.image?.attr,
				className: `${obj1.image?.attr?.className ?? ''} ${obj2.image?.attr?.className ?? ''}`,
				style: {
					...obj1.image?.attr?.style,
					...obj2.image?.attr?.style,
				},
			},
		},
		video: {
			...obj1.video,
			...obj2.video,
			attr: {
				...obj1.video?.attr,
				...obj2.video?.attr,
				className: `${obj1.video?.attr?.className ?? ''} ${obj2.video?.attr?.className ?? ''}`,
				style: {
					...obj1.video?.attr?.style,
					...obj2.video?.attr?.style,
				},
				config: {
					...obj1.video?.attr?.config,
					...obj2.video?.attr?.config,
					file: {
						...obj1.video?.attr?.config?.file,
						...obj2.video?.attr?.config?.file,
						attributes: {
							...obj1.video?.attr?.config?.file?.attributes,
							...obj2.video?.attr?.config?.file?.attributes,
						},
					},
				},
			},
		},
	};
}

export { merge, renderHeader, renderImage, renderList, renderSpan, renderVideo, renderWrapper };

