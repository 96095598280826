import { useTranslation } from "react-i18next";

export const VrSupport = () => {
	const { t } = useTranslation();

	return (
		<div className="base-component component-padding column">
			<div className="base-component-text-container-wide">
				<h2 className="component-header-normal">{t("functionElement12Header")}</h2>
				<span className="base-component-span">{t("functionElement12Span")} </span>
			</div>
			<img style={{width: "102%", height: "auto"}} alt="VR glasses"
				src="/assets/img/functionDescriptionsImages/vrSupportImage.webp" />
		</div>
	)
}