const BrandArrays = [
	"/assets/img/brandsImages/IstikbalLogo.webp",
	"/assets/img/brandsImages/GabbaLogo.webp",
	"/assets/img/brandsImages/YolLogo.webp",
	"/assets/img/brandsImages/TiliaLogo.webp",
	"/assets/img/brandsImages/TepeHomeLogo.webp",
	"/assets/img/brandsImages/MonettHomeLogo.webp",
	"/assets/img/brandsImages/SempreLogo.webp",
	"/assets/img/brandsImages/SelimogluLogo.webp",
	"/assets/img/brandsImages/BellonaLogo.webp",
	"/assets/img/brandsImages/VestelLogo.webp",
	"/assets/img/brandsImages/MondiLogo.webp",
	"/assets/img/brandsImages/AmactareLogo.webp",
	"/assets/img/brandsImages/SimetriLogo.webp",
	"/assets/img/brandsImages/PusulaDekoratifLogo.webp",
	"/assets/img/brandsImages/LunicaLogo.webp",
	"/assets/img/brandsImages/HarigarigaLogo.webp",
	"/assets/img/brandsImages/SimurgLogo.webp",
	"/assets/img/brandsImages/SancakliLogo.webp",
	"/assets/img/brandsImages/MNBilgiliLogo.webp",
	"/assets/img/brandsImages/CreavitLogo.webp",
	"/assets/img/brandsImages/EkohaliLogo.webp",
	"/assets/img/brandsImages/TurkcellPasajLogo.webp",
	"/assets/img/brandsImages/UzaqHomeLogo.webp",
	"/assets/img/brandsImages/BlendrLogo.webp",
	"/assets/img/brandsImages/LazzoniLogo.webp",
	"/assets/img/brandsImages/BoztasHomeLogo.webp",
	"/assets/img/brandsImages/ChakraLogo.webp",
	"/assets/img/brandsImages/MadameCocoLogo.webp",
	"/assets/img/brandsImages/BenimOdamLogo.webp",
	"/assets/img/brandsImages/BoztasLogo.webp",
	"/assets/img/brandsImages/HomageLogo.webp",
];

export default BrandArrays;