const title = "SUGAR TECHNOLOGY YAZILIM A.Ş. GİZLİLİK SÖZLEŞMESİ"

const article1 = {
    title: "Veri Toplama ve Kullanımı",
    content: [
        "a. Şirket, Kullanıcı tarafından Platform üzerinde sağlanan kişisel bilgileri toplayabilir. Bu bilgiler, kullanıcının kimlik bilgileri, iletişim bilgileri ve demografik bilgileri içerebilir.",
        "b. Şirket, Kullanıcı'nın kişisel bilgilerini, Platform üzerinden hizmet sunumu, kullanıcı deneyiminin iyileştirilmesi, pazarlama faaliyetleri ve yasal gerekliliklerin yerine getirilmesi amacıyla kullanabilir."
    ]
};

const article2 = {
    title: "Veri Paylaşımı",
    content: [
        "a. Şirket, Kullanıcı'nın kişisel bilgilerini, yasal gerekliliklere uygun olarak yetkili mercilere veya iiş ortaklarına aktarabilir",
        "b. Şirket, Kullanıcı'nın izni olmadan kişisel bilgileri üçüncü taraflarla ticari amaçlarla paylaşmayacaktır."
    ],
};

const article3 = {
    title: "Veri Güvenliği",
    content: [
        "a. Şirket, Kullanıcı'nın kişisel bilgilerinin güvenliğini sağlamak için uygun teknik ve idari önlemler alır.",
        "b. Şirket, olası veri ihlallerini tespit etmek ve müdahale etmek için gerekli güvenlik önlemlerini almaya çalışır. Bununla birlikte, herhangi bir güvenlik önlemi kesinlikle garanti edilemez ve Şirket, veri ihlali durumunda herhangi bir sorumluluk kabul etmez."
    ]
};

const article4 = {
    title: "Topladığımız Bilgiler",
    content: [
        "Hizmetlerimizi kullandığınızda aşağıdaki türde bilgileri toplayabiliriz:",
        "a. Adınız, e-posta adresiniz, telefon numaranız ve posta adresiniz gibi doğrudan sizinle ilişkilendirilebilecek bilgiler.",
        "b. Ziyaret ettiğiniz sayfalar, tıklattığınız bağlantılar ve diğer izleme verileri dahil olmak üzere Platform’u nasıl kullandığınıza ilişkin bilgiler.",
        "c. Hizmetlerimizin performansını izlemek ve deneyiminizi kişiselleştirmek için çerezleri ve diğer izleme teknolojileri."
    ]
};

const article5 = {
    title: "Üçüncü Taraf Bağlantıları",
    content: [
        "a. Platform, üçüncü taraf internet sitelerine veya hizmetlere bağlantılar içerebilir. Bu üçüncü taraf sitelerin veya hizmetlerin gizlilik uygulamaları ve içeriklerinden Şirket sorumlu değildir. Kullanıcı, bu sitelere veya hizmetlere erişirken kendi sorumluluğunda olduğunu kabul eder."
    ]
};

const article6 = {
    title: "Gizlilik Sözleşmesinin Değişiklikleri",
    content: [
        "a. Şirket, bu gizlilik sözleşmesini kendi takdirine bağlı olarak dilediği zaman değiştirme hakkına sahiptir.",
        "b. Değişiklikler, Şirket tarafından Platform üzerinden yayınlanarak yürürlüğe girer. Kullanıcı, değişiklikleri takip etmekle yükümlüdür."
    ]
};

const article7 = {
    title: "Haklarınız",
    content: [
        "Kişisel bilgilerinizle ilgili olarak aşağıdakiler dahil belirli haklara sahipsiniz:",
        "a. Bilgilerinize erişim talep etme hakkı.",
        "b. Bilgilerinizin düzeltilmesini veya silinmesini talep etme hakkı.",
        "c. İşleme itiraz hakkı.",
        "d. Veri taşınabilirliği hakkı."
    ]
};

const article8 = {
    title: "İletişim",
    content: [
        "Kullanıcının, Şirket ile gerekli durumlarda iletişim kurmak için çeşitli yolları vardır. Bu yollar şunlardır:",
        "i. Doğrudan Konuşma: İnternet sitesi ve uygulama üzerinde yer alan iletişim numalarını arayarak, doğrudan iletişim sağlayabilirsiniz.",
        "i. E-Posta: İnternet sitesi ve uygulama üzerinde yer alan iletişim bilgileriyle e-posta kanalı üzerinden iletişime geçebilirsiniz.",
        "i. Sosyal Medya: Şirket’in sosyal medya hesapları üzerinden özel mesaj yoluyla iletişime geçebilirsiniz.",
        "iv. Tüketici Koruma Kuruluşlarına Başvurma: Eğer Şirket ile bir sorun yaşar ve umduğunuz çözüme ulaşamazsanız, tüketici koruma kuruluşlarına başvurabilirsiniz. Tüketici koruma kuruluşları, tüketici haklarının korunmasına yönelik yasaların uygulanmasını denetler ve şikayetlerinizi değerlendirir. Ülkenizdeki veya bölgenizdeki tüketici koruma kuruluşlarını bulmak için yerel tüketici koruma ofislerini veya internet üzerindeki tüketici hakları web sitelerin araştırabilirsiniz.",
    ]
};

const privacyPolicyContent = {
    title: title,
    articles: [
        article1,
        article2,
        article3,
        article4,
        article5,
        article6,
        article7,
        article8
    ]
};

export { privacyPolicyContent };