import { useRef } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Keyboard, Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { HeaderWithLine } from "../Common/HeaderWithLine";
import FinalRenderArrays from "./FinalRenderArrays";
import "./FinalRenders.css";

export const FinalRenders = () => {
	const { t } = useTranslation();
	const topSwiper = useRef();
	const bottomSwiper = useRef();
	const renders = FinalRenderArrays;

	const topSwiperSettings = {
		className: "final-render-top-swiper",
		modules: [Navigation, Keyboard, EffectFade],
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		speed: 1000,
		grabCursor: true,
		rewind: true,
		keyboard: true,
		direction: "horizontal",
		slidesPerView: 1,
		onSlideChange: () => {
			if (bottomSwiper.current && topSwiper.current && bottomSwiper.current.swiper) {
				bottomSwiper.current.swiper.slideToLoop(
					bottomSwiper.current.swiper.slides
						.find((slide) => slide.id === topSwiper.current.swiper.activeIndex.toString()).id
				);
			}
		}
	};

	const bottomSwiperSettings = {
		className: "final-render-bottom-swiper",
		modules: [Navigation, Keyboard, Mousewheel],
		speed: 1000,
		loop: true,
		loopAdditionalSlides: 4,
		initialSlide: 0,
		mousewheel: true,
		centeredSlides: true,
		slideToClickedSlide: true,
		slidesPerView: "auto",
		onInit: () => {
			bottomSwiper.current.swiper.slideToLoop(4);
		},
		onSlideChange: () => {
			if (bottomSwiper.current && topSwiper.current && topSwiper.current.swiper) {
				topSwiper.current.swiper.slideTo(
					bottomSwiper.current.swiper.slides[bottomSwiper.current.swiper.activeIndex].id
				);
			}
		},
	};

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<HeaderWithLine
					color="#1EA9CD"
					size="large"
					weight="700"
					title={t("finalRendersTitle")}
				/>
				<div className="base-component-text-container-wide">
					<span className="base-component-span">{t("finalRendersSpan")}</span>
				</div>
				<div className="final-render-swiper-container">
					<Swiper
						ref={topSwiper}

						{...topSwiperSettings}>
						{renders.map((render, index) => {
							return (
								<SwiperSlide key={render.id} id={index}>
									<img
										className="swiper-lazy"
										loading="lazy"
										alt={render.alt}
										src={render.src} />
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className="swiper-shading-container">
						<div className="swiper-shading"></div>
						<Swiper
							ref={bottomSwiper}
							{...bottomSwiperSettings}>
							{renders.map((render, index) => {
								return (
									<SwiperSlide
										key={render.id + "_thumb"}
										className="final-render-bottom-slide"
										id={index}>
										<img
											className="swiper-lazy swiper-lazy-loaded"
											loading="lazy"
											alt={render.alt}
											src={render.src.replace(".webp", "_thumb.webp")}
										/>
										<span className="slider-title">{render.title}</span>
									</SwiperSlide>
								);
							})}
						</Swiper>
						<div className="swiper-shading"></div>
					</div>
				</div>
			</div>
		</div>
	);
};
