import "./Logo.css";

const Logo = ({ brandLogo, classname }) => {
	return (
		<div className={classname}>
			<img className="brandLogo" src={brandLogo} alt={"a company logo"} />
		</div>
	);
}

export { Logo };
