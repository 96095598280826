import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { LoginSocialApple, LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import lottieFileLoading from "../../animations/loading.json";
import lottieFile from "../../animations/tick.json";
import { useApi } from "../../api/useApi";
import { AuthContext } from "../../auth/AuthProvider";
import { useStringLocalStorage } from "../../storage/useStorage";
import "./Authentication.css";

export const Authentication = (props) => {
	const { t } = useTranslation();

	const [isShowCreateAccount, setIsShowCreateAccount] = useState(false);
	const [isRejected, setIsRejected] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isClickedLogin, setIsClickedLogin] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showErrorMessageName, setShowErrorMessageName] = useState(false);
	const [mail, setMail] = useState("");
	const [name, setName] = useState("");
	const [resetMail, setResetMail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordShow, setPasswordShow] = useState("password");
	const [isTablet, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [captchaLogin, setCaptchaLogin] = useState(false);
	const [captchaCreate, setCaptchaCreate] = useState(false);
	const [responseCreateUser, setResponseCreateUser] = useState(false);
	const [responseCreateUserOk, setResponseCreateUserOk] = useState(false);
	const [errorMessage, setErroMessage] = useState("Beklenmedik bir hata oluştu lütfen tekrar deneyin.");
	const [isExistUser, setIsExistUser] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);

	const api = useApi();
	const { login, show, onSuccess, setShowFalse, onError, create } = useContext(AuthContext);

	// const storage = useLocalStorage();
	const [userMail, setUserMail] = useStringLocalStorage(
		"userMail",
		""
	);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 800);
			setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleSubmitLogin = async (event) => {
		if (captchaLogin) {
			event.preventDefault();

			if (!mail)
				setShowErrorMessage(true);

			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
			if (!(emailRegex.test(mail)))
				setShowErrorMessage(true);

			if (!name)
				setShowErrorMessageName(true);
			else
				setShowErrorMessageName(false)

			if (password && mail) {
				const sugarData = {
					email: mail,
					password: password,
					provider: "sugar"
				}
				try {
					setIsLoading(true);
					setIsClickedLogin(false);
					// const userData = await login(sugarData)
					await login(sugarData)
					setUserMail(sugarData.email);
					await api.sendLink();
					setIsLoading(false);
					if (onSuccess)
						onSuccess();

				} catch (error) {
					setShowFalse()
					setIsRejected(true)
					if (onError)
						onError();
					setIsLoading(false);
				}
			}
		}
	}
	const handleSubmitCreate = async (event) => {
		if (captchaCreate) {
			event.preventDefault();
			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
			if (!(emailRegex.test(mail)))
				setShowErrorMessage(true);
			if (!name)
				setShowErrorMessageName(true);
			else
				setShowErrorMessageName(false)
			if (!mail)
				setShowErrorMessage(true);
			else
				setShowErrorMessage(false);

			if (name && mail) {
				const data = {
					name: name,
					email: mail,
					provider: "user"
				}
				try {
					setIsLoading(true);
					setIsShowCreateAccount(false);
					const userData = await create(data);
					setIsLoading(false);
					if (userData.data === null || userData.data === "") {
						setErroMessage("Kullanıcı zaten mevcut");
						setIsExistUser(true);
						setResponseCreateUser(true);
						setResponseCreateUserOk(false);
					} else {
						setResponseCreateUser(true);
						setResponseCreateUserOk(true);
						setUserMail(data.email);
					}
				} catch (e) {
					setResponseCreateUser(true);
					setResponseCreateUserOk(false);
					setIsShowCreateAccount(false);
					setIsLoading(false);
				}
			}
		}
	}

	const convertGoogleRequest = (response) => {
		const data = {
			authenticationToken: response.credential,
			credential: response.credential,
			name: response.given_name,
			lastName: response.family_name,
			imageUrl: response.picture,
			email: response.email,
			provider: "google"
		}

		return data;
	}
	const convertFacebookRequest = (response) => {
		const data = {
			authenticationToken: response.data.accessToken,
			credential: response.data.accessToken,
			name: response.data.first_name,
			lastName: response.data.last_name,
			imageUrl: response.data.picture.data.url,
			email: response.data.email,
			userId: response.data.userID,
			provider: "facebook"
		}

		return data;
	}
	// const convertAppleRequest = (response) => {
	// 	const data = {
	// 		authenticationToken: response.data.accessToken,
	// 		credential: response.data.accessToken,
	// 		name: response.data.first_name,
	// 		lastName: response.data.last_name,
	// 		imageUrl: response.data.picture.data.url,
	// 		email: response.data.email,
	// 		userId: response.data.userID,
	// 		provider: "facebook"
	// 	}

	// 	return data;
	// }


	const handleResetPasswordClick = () => {
		setIsShowCreateAccount(false);
		setIsClickedLogin(false);
		setShowResetPassword(true);
	}

	const handleResetPasswordButton = async () => {
		try {
			setIsLoading(true);
			setShowResetPassword(false);
			const response = await api.requestResetPassword(resetMail);
			if (response.status === HttpStatusCode.Ok) {
				setIsLoading(false);
			}
		} catch (e) {
			setResponseCreateUser(true);
			setResponseCreateUserOk(false);
			setIsLoading(false);
		}
	}


	if (show) {
		return (
			<div className={`${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} alertOverlay`} style={{ display: "flex" }}>

				{/* ACCOUNT OPTIONS CONTAINER */}

				<div className="authenticationContainer" style={{ display: (!isShowCreateAccount && !isClickedLogin && !isRejected && !isLoading && !responseCreateUser && !isLoading && !showResetPassword) ? "flex" : "none" }}>
					<img alt='close icon' src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setShowFalse() }} />
					<div className="authHeaders">
						<h2 className="authHeader"> {t("welcomeRapidRender")} </h2>
						<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
							<span className="authSpan"> {t("alreadyHaveAccount")}</span>
							<span className="authHrefText" onClick={() => { setIsClickedLogin(true) }}> {t("login")} </span>
						</div>
					</div>

					<div className="authOptions">
						<LoginSocialGoogle
							className="loginSocial"
							client_id="336241674137-gs27nm7hd7kilrfssmk4ue1ulivvd2cm.apps.googleusercontent.com"
							typeResponse="idToken"
							scope="openId profile email"
							onResolve={async ({ provider, data }) => {
								try {
									setIsLoading(true);
									setIsShowCreateAccount(false);
									// const userData = await login(convertGoogleRequest(data));
									await login(convertGoogleRequest(data));
									setUserMail(data.email);
									await api.sendLink();
									setIsLoading(false);
									if (onSuccess)
										onSuccess();

								} catch (error) {
									setShowFalse()
									setIsRejected(true)
									// console.log(error, " HATAAA!!!")
									if (onError)
										onError();
									setIsLoading(false);
								}
							}}
							onReject={(response) => { console.log(response); console.log("error") }}
						>
							<div className="optionContainer">
								<div className="optionButton">
									<img alt="google logo" className="optionLogo" src="/assets/img/authOptions/google.svg" />
									<span className="optionText"> {t("signInGoogle")} </span>
								</div>
							</div>
						</LoginSocialGoogle>
						<LoginSocialFacebook
							className="loginSocial"
							appId="666940601756668"
							fieldsProfile={
								"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email"
							}
							onResolve={async (response) => {
								try {
									setIsLoading(true);
									setIsShowCreateAccount(false);
									// const userData = await login(convertFacebookRequest(response));
									await login(convertFacebookRequest(response));
									setShowFalse();
									setUserMail(response.data.email);
									await api.sendLink();
									setIsLoading(false);
									if (onSuccess)
										onSuccess();

								} catch (error) {
									setShowFalse();
									if (onError)
										onError();
								}
							}}
							onReject={(response) => { console.log(response) }}
						>
							<div className="optionContainer"  >
								<div className="optionButton">
									<img alt="facebook logo" className="optionLogo" src="/assets/img/authOptions/facebook.svg" />
									<span className="optionText"> {t("signInFacebook")} </span>
								</div>
							</div>
						</LoginSocialFacebook>

						<LoginSocialApple
							className="loginSocial"
							client_id="2H994K36RP"
							onResolve={(response) => { console.log(response) }}
							onReject={(response) => { console.log(response) }}
						>
							<div className="optionContainer" >
								<div className="optionButton">
									<img alt="apple logo" className="optionLogo" src="/assets/img/authOptions/apple.svg" />
									<span className="optionText"> {t("signInApple")} </span>
								</div>
							</div>
						</LoginSocialApple>


					</div>
					<div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" }}>
						<div className="authMailButton" onClick={() => { setIsShowCreateAccount(true) }}>
							<span className="authMailText"> {t("continueWithMail")} </span>
						</div>
						<div className="authPrivacyTextContainer">
							<p className="authPrivacyText">
								{t("agreementTextBegin")}{' '}
								<span href={"/termsOfUse"} className="authHrefText" style={{ textDecoration: "none", fontSize: "13px" }}>
									<a href={"/termsOfUse"} target={'_blank'} rel={'noopener noreferrer'}>{t("termsOfUse")}</a>
								</span>{' '}
								{t("and")}{' '}
								<span className="authHrefText" style={{ textDecoration: "none", fontSize: "13px" }}>
									<a href={"/privacyPolicy"} target={'_blank'} rel={'noopener noreferrer'}>{t("privacyPolicy")}</a>
								</span>
								{t("agreementTextEnd")}
							</p>
						</div>
					</div>

				</div>

				{/* CREATE ACCOUNT CONTAINER */}

				<div className="authCreateAccountContainer" style={{ display: (isShowCreateAccount && !isClickedLogin) ? "flex" : "none" }}>
					<img alt="an x" src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setShowFalse(); setIsShowCreateAccount(false); setShowErrorMessage(false); setShowErrorMessageName(false); }} />

					<div className="authCreateAccountHeaders">
						<div className="authCreateAccountHeader">
							<img alt="left arrow" style={{ cursor: "pointer" }} src="/assets/img/authOptions/left.svg" onClick={() => { setIsShowCreateAccount(false); setShowErrorMessage(false); setShowErrorMessageName(false); }} />
							<h2 className="authCreateAccountHeaderText"> {t("createAccount")} </h2>
						</div>
						<span className="authCreateAccountHeaderSpan"> {t("creatingAccount")} </span>
					</div>

					<div className="authCreateAccountInputs">
						<div className="authCreateAccountInputContainer">
							<img alt="unkown profile" className="authCreateAccountInputImage" src="/assets/img/authOptions/head.svg" />
							<input className="authCreateAccountInput" value={name} onChange={(e) => setName(e.target.value)} placeholder={t("enterName")}>
							</input>
						</div>
						<div style={{ display: showErrorMessageName ? "flex" : "none", alignItems: "center", justifyContent: "flex-start", gap: "10px", width: isMobile ? "90%" : "50%" }}>
							<img alt="error" src="/assets/img/footerImages/error.svg" />
							<span className="footerErrorMessage"> {t("errorName")} </span>
						</div>
						<div className="authCreateAccountInputContainer">
							<img alt="envelope" className="authCreateAccountInputImage" src="/assets/img/authOptions/mail.svg" />
							<input className="authCreateAccountInput" value={mail} onChange={(e) => setMail(e.target.value)} placeholder={t("enterMail")}>
							</input>
						</div>
						<div style={{ display: showErrorMessage ? "flex" : "none", alignItems: "center", justifyContent: "flex-start", gap: "10px", width: isMobile ? "90%" : "50%" }}>
							<img alt="error" src="/assets/img/footerImages/error.svg" />
							<span className="footerErrorMessage"> {t("errorMail")} </span>
						</div>
					</div>
					<ReCAPTCHA
						sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
						className="reCaptcha"
						onChange={val => setCaptchaCreate(val)}
					/>
					<div className="authMailButton" style={{ background: captchaCreate ? "#1EA9CD" : "#495057", cursor: captchaCreate ? "pointer" : "default" }} onClick={handleSubmitCreate}>
						<span className="authMailText"> {t("continue")} </span>
					</div>
				</div>


				{/* LOGIN CONTAINER */}


				<div className="authCreateAccountContainer" style={{ display: isClickedLogin ? "flex" : "none" }}>
					<img alt="close icon" src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setShowFalse(); setIsClickedLogin(false) }} />
					<div className="authCreateAccountHeaders">
						<div className="authCreateAccountHeader">
							<img alt="close icon" style={{ cursor: "pointer" }} src="/assets/img/authOptions/left.svg" onClick={() => { setIsClickedLogin(false) }} />
							<h2 className="authCreateAccountHeaderText"> {t("login")} </h2>
						</div>
						<span className="authCreateAccountHeaderSpan"> {t("loginAccountText")} </span>
					</div>
					<div className="authCreateAccountInputs">
						<div className="authCreateAccountInputContainer">
							<img alt="envelope" className="authCreateAccountInputImage" src="/assets/img/authOptions/mail.svg" />
							<input className="authCreateAccountInput" value={mail} onChange={(e) => setMail(e.target.value)} placeholder={t("enterMailNumber")}>
							</input>
						</div>

						<div className="authCreateAccountInputContainer">
							<img alt="lock" className="authCreateAccountInputImage" src="/assets/img/authOptions/lock.svg" />
							<input className="authCreateAccountInput" value={password} type={passwordShow} onChange={(e) => setPassword(e.target.value)} placeholder={t("enterPassword")}>
							</input>
							<img alt="an eye" className="passwordEyeImage" src="/assets/img/authOptions/closeEye.svg" onClick={() => { if (passwordShow === "password") setPasswordShow(""); else setPasswordShow("password") }} />

						</div>
						<ReCAPTCHA
							sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
							className="reCaptcha"
							onChange={val => setCaptchaLogin(val)}
						/>
						<div className="authMailButton" style={{ background: captchaLogin ? "#1EA9CD" : "#495057", cursor: captchaLogin ? "pointer" : "default" }} onClick={handleSubmitLogin}>
							<span className="authMailText"> {t("continue")} </span>
						</div>
						<span className="forgotPasswordText" onClick={handleResetPasswordClick}> {t("forgotPassword")} </span>

					</div>
				</div>

				{/* Reset Password */}

				<div className="authCreateAccountContainer" style={{ display: showResetPassword ? "flex" : "none" }}>
					<img alt="close icon" src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setShowFalse(); setShowResetPassword(false) }} />
					<div className="authCreateAccountHeaders">
						<div className="authCreateAccountHeader">
							<img alt="left arrow" style={{ cursor: "pointer" }} src="/assets/img/authOptions/left.svg" onClick={() => { setShowResetPassword(false) }} />
							<h2 className="authCreateAccountHeaderText"> {t('resetPasswordHeader')} </h2>
						</div>
						<h2 className="authCreateAccountHeaderSpan"> {t('resetPasswordSpan')} </h2>
					</div>
					<div className="authCreateAccountInputs">
						<div className="authCreateAccountInputContainer">
							<img alt="envelope" className="authCreateAccountInputImage" src="/assets/img/authOptions/mail.svg" />
							<input className="authCreateAccountInput" value={resetMail} onChange={(e) => setResetMail(e.target.value)} placeholder={t("enterMailNumber")}>
							</input>
						</div>
						<ReCAPTCHA
							sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
							className="reCaptcha"
							onChange={val => setCaptchaLogin(val)}
						/>
						<div className="authMailButton" style={{ background: captchaLogin ? "#1EA9CD" : "#495057", cursor: captchaLogin ? "pointer" : "default" }} onClick={handleResetPasswordButton}>
							<span className="authMailText"> {t("continue")} </span>
						</div>
					</div>
				</div>



				<div className="authCreateAccountContainer" style={{ display: responseCreateUser ? "flex" : "none" }}>
					<img alt="close icon" src="/assets/img/closeIcon.svg" className="closeIconPopup" onClick={() => { setShowFalse(); setResponseCreateUser(false); }} />
					{
						responseCreateUserOk ?
							<>
								<div className="affirmativeThanksMessage">
									{t("thanks")}
								</div>
								<Lottie
									loop
									animationData={lottieFile}
									play
									style={{ width: "50%" }}
								/>
								<div className="affirmativeMessage">
									{t('mailAffirmativeActivation')}
								</div>
								<div className="affirmativeMail">
									{userMail}
								</div>
							</>

							:
							<>
								<img alt="error" src="/assets/img/errorImage.svg" />
								<div className="rejectMessageTitle">
									{t("oops")} {!isExistUser && t('error')}
								</div>
								<div className="rejectMessageSpan">
									{errorMessage}
								</div>
								<div className="authMailButton" onClick={() => { setResponseCreateUser(false); show(); }}>
									<span className="authMailText">
										{t('tryAgain')}
									</span>
								</div>
							</>

					}

				</div>




				<div className="authCreateAccountContainer" style={{ display: isLoading ? "flex" : "none" }}>
					<Lottie
						loop
						animationData={lottieFileLoading}
						play
						style={{ width: "50%" }}
					/>
				</div>

			</div >

		)
	}
	return (<></>);
}


