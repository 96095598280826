import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AISupport } from "../FunctionDescriptionsComponent/AiSupport";
import { AutoFloor } from "../FunctionDescriptionsComponent/AutoFloor";
import { CadFileImport } from "../FunctionDescriptionsComponent/CadFileImport";
import { CloudRender } from "../FunctionDescriptionsComponent/CloudRender";
import { Commission } from "../FunctionDescriptionsComponent/Commission";
import { Curtain } from "../FunctionDescriptionsComponent/Curtain";
import { GroundWalls } from "../FunctionDescriptionsComponent/GroundWalls";
import { Kartela } from "../FunctionDescriptionsComponent/Kartela";
import { Proforma } from "../FunctionDescriptionsComponent/Proforma";
import { ProjectManagment } from "../FunctionDescriptionsComponent/ProjectManagmentComponent";
import { ReadyRooms } from "../FunctionDescriptionsComponent/ReadyRooms";
import { RoomScanning } from "../FunctionDescriptionsComponent/RoomScanning";
import { VrSupport } from "../FunctionDescriptionsComponent/VrSupport";
import { WallSlats } from "../FunctionDescriptionsComponent/WallSlats";
import { WhatsAppAndEmail } from "../FunctionDescriptionsComponent/WhatsAppAndEmail";
import { LittleDownload } from "../LittleDownloadComponent/LittleDownloadComponent";
import { ProductsFurniture } from "../ProductsComponent/ProductsFurniture";
import "../componentCommonStyle.css";
import "./FunctionsComponent.css";

const functionArray = [
	{
		url: "/assets/img/functionsImages/Odatarama.svg",
		header: "functionElement1Header",
		span: "functionElement1Description",
		alt: 'room scanning'
	},
	{
		url: "/assets/img/functionsImages/dataLibrary.svg",
		header: "functionElement2Header",
		span: "functionElement2Description",
		alt: 'library'
	},
	{
		url: "/assets/img/functionsImages/Zemin.svg",
		header: "functionElement3Header",
		span: "functionElement3Description",
		alt: 'floor'
	},
	{
		url: "/assets/img/functionsImages/Kartela.svg",
		header: "functionElement4Header",
		span: "functionElement4Description",
		alt: 'cartela'
	},
	{
		url: "/assets/img/functionsImages/perde.svg",
		header: "functionElement5Header",
		span: "functionElement5Description",
		alt: 'curtain'
	},
	{
		url: "/assets/img/functionsImages/çita.svg",
		header: "functionElement6Header",
		span: "functionElement6Description",
		alt: 'slat'
	},
	{
		url: "/assets/img/functionsImages/zeminDuvar.svg",
		header: "functionElement7Header",
		span: "functionElement7Description",
		alt: 'floor and walls'
	},
	{
		url: "/assets/img/functionsImages/share.svg",
		header: "functionElement9Header",
		span: "functionElement9Description",
		alt: 'sharing options'
	},
	{
		url: "/assets/img/functionsImages/cloud.svg",
		header: "functionElement8Header",
		span: "functionElement8Description",
		alt: 'cloud'
	},
	{
		url: "/assets/img/functionsImages/dataMarka.svg",
		header: "functionElement10Header",
		span: "functionElement10Description",
		alt: 'brand logos'
	},
	{
		url: "/assets/img/functionsImages/VR.svg",
		header: "functionElement12Header",
		span: "functionElement12Description",
		alt: 'VR glasses'
	},
	{
		url: "/assets/img/functionsImages/CADFile.svg",
		header: "functionElement11Header",
		span: "functionElement11Description",
		alt: 'CAD file '
	},
	{
		url: "/assets/img/functionsImages/responsive.svg",
		header: "functionElement14Header",
		span: "functionElement14Description",
		alt: 'responsive design'
	},
	{
		url: "/assets/img/functionsImages/readyRoom.svg",
		header: "functionElement13Header",
		span: "functionElement13Description",
		alt: 'ready room'
	},
	{
		url: "/assets/img/functionsImages/komisyon.svg",
		header: "functionElement15Header",
		span: "functionElement15Description",
		alt: 'commission'
	},
	{
		url: "/assets/img/functionsImages/whatsApp.svg",
		header: "functionElement16Header",
		span: "functionElement16Description",
		alt: 'whatsapp logo'
	}
];

export const Functions = () => {
	const { t } = useTranslation();

	const [showBackToTopButton, setShowBackToTopButton] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showFunctions, setShowFunctions] = useState("none");

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleItemClick = (index) => {
		const descriptionContainer = document.querySelectorAll(".component-wrapper");

		if (descriptionContainer) {
			setShowBackToTopButton(true);
			setScrollPosition(850);
			const targetElement = descriptionContainer[index].getBoundingClientRect().bottom;
			const targetPosition = targetElement + window.scrollY;

			window.scrollTo({ top: targetPosition, behavior: "smooth" })
		}
		const componentRect = document.querySelector(".component-wrapper").getBoundingClientRect();
		if (componentRect.bottom >= 0 || componentRect.top <= window.innerHeight) {
			setShowBackToTopButton(false);
		} else {
			setShowBackToTopButton(true);
		}
	};

	const handleBackToTop = () => {
		setShowBackToTopButton(false);
		window.scrollTo({ top: scrollPosition, behavior: "smooth" });
	};

	const onClickMore = () => {
		if (showFunctions === "none")
			setShowFunctions("");
		else
			setShowFunctions("none")
	}

	const subComponents = [
		RoomScanning,
		ProductsFurniture,
		AutoFloor,
		Kartela,
		Curtain,
		WallSlats,
		GroundWalls,
		Proforma,
		AISupport,
		CloudRender,
		VrSupport,
		CadFileImport,
		ProjectManagment,
		ReadyRooms,
		Commission,
		WhatsAppAndEmail
	];

	return (
		<div className="functionsComponent">
			<div className="base-component component-padding column">
				<div className='base-component-text-container-wide'>
					<h2 className="component-header-large">{t("functionsComponentHeader")}</h2>
				</div>
				<div className="functionsContainer">
					{functionArray.map((item, index) => (
						<div key={index} className="functionsItem"
							style={{ display: (windowWidth <= 890 && index > 5) && showFunctions }}
							onClick={() => handleItemClick(index)}>
							<img src={item.url} className="functionsImage" alt={item.alt} />
							<h2 className="functionsItemHeader">{t(item.header)}</h2>
							<span className="functionsItemName">{t(item.span)}</span>
						</div>
					))}
				</div>
				{
					windowWidth <= 890 &&
					<div className="moreFunctionsButton" onClick={onClickMore}>
						<span className="moreFunctionsText">{showFunctions ? t("moreFunctions") : t("hideFunctions")}</span>
					</div>
				}
			</div>
			<div className="descriptionContainers">
				{
					subComponents.map((Component, index) => (
						<Fragment key={index}>
							<div className="component-wrapper"
								index={index}
								style={{
									background: Component === WhatsAppAndEmail ? "#C7DCCA"
										: (index % 2 === 0) ? "#EEE" : "#FFF"
								}}
							>
								<Component />
							</div>
							<LittleDownload />
						</Fragment>
					))
				}
			</div>
			{showBackToTopButton && (
				<img className="handleBackToTopButton"
					onClick={handleBackToTop}
					width="100%"
					alt="arrow facing up"
					src="/assets/img/upload.svg" />
			)}
		</div>
	)
}