export const faqContent = [
	{
		q: "faqQ0",
		a: [
			{ type: "span", id: "faqQ0A0Span" },
			{ type: "header", id: "faqQ0A1Header" },
			{ type: "span", id: "faqQ0A1Span" },
			{ type: "header", id: "faqQ0A2Header" },
			{ type: "span", id: "faqQ0A2Span" },
			{ type: "header", id: "faqQ0A3Header" },
			{ type: "span", id: "faqQ0A3Span" },
			{ type: "header", id: "faqQ0A4Header" },
			{ type: "span", id: "faqQ0A4Span" },
			{ type: "header", id: "faqQ0A5Header" },
			{ type: "span", id: "faqQ0A5Span" },
			{ type: "header", id: "faqQ0A6Header" },
			{ type: "span", id: "faqQ0A6Span" },
			{ type: "header", id: "faqQ0A7Header" },
			{ type: "span", id: "faqQ0A7Span" }
		],
	},
	{
		q: "faqQ1",
		a: [
			{ type: "span", id: "faqQ1A0Span" },
			{ type: "header", id: "faqQ1A1Header" },
			{ type: "span", id: "faqQ1A1Span" },
			{ type: "header", id: "faqQ1A2Header" },
			{ type: "span", id: "faqQ1A2Span" },
			{ type: "header", id: "faqQ1A3Header" },
			{ type: "span", id: "faqQ1A3Span" },
			{ type: "header", id: "faqQ1A4Header" },
			{ type: "span", id: "faqQ1A4Span" },
			{ type: "header", id: "faqQ1A5Header" },
			{ type: "span", id: "faqQ1A5Span" }
		],
	},
	{
		q: "faqQ2",
		a: [
			{ type: "header", id: "faqQ2A0Header" },
			{ type: "span", id: "faqQ2A0Span" }
		],
	},
	{
		q: "faqQ3",
		a: [
			{ type: "span", id: "faqQ3A0Span" },
			{ type: "header", id: "faqQ3A1Header" },
			{ type: "span", id: "faqQ3A1Span" },
			{ type: "header", id: "faqQ3A2Header" },
			{ type: "span", id: "faqQ3A2Span" }
		],
	},
	{
		q: "faqQ4",
		a: [
			{ type: "span", id: "faqQ4A0Span" },
			{ type: "span", id: "faqQ4A1Span" },
			{ type: "span", id: "faqQ4A2Span" },
			{ type: "span", id: "faqQ4A3Span" }
		],
	},
	{
		q: "faqQ5",
		a: [
			{ type: "span", id: "faqQ5A0Span" }
		],
	},
	{
		q: "faqQ6",
		a: [
			{ type: "header", id: "faqQ6A0Header" },
			{ type: "span", id: "faqQ6A0Span" },
			{ type: "header", id: "faqQ6A1Header" },
			{ type: "span", id: "faqQ6A1Span" },
			{ type: "header", id: "faqQ6A2Header" },
			{ type: "span", id: "faqQ6A2Span" },
			{ type: "header", id: "faqQ6A3Header" },
			{ type: "span", id: "faqQ6A3Span" },
			{ type: "header", id: "faqQ6A4Header" },
			{ type: "span", id: "faqQ6A4Span" },
			{ type: "header", id: "faqQ6A5Header" },
			{ type: "span", id: "faqQ6A5Span" },
			{ type: "header", id: "faqQ6A6Header" },
			{ type: "span", id: "faqQ6A6Span" }
		],
	},
	{
		q: "faqQ7",
		a: [
			{ type: "span", id: "faqQ7A0Span" },
			{ type: "span", id: "faqQ7A1Span" },
			{
				type: "list", items: [
					"faqQ7A1List0",
					"faqQ7A1List1",
					"faqQ7A1List2",
					"faqQ7A1List3",
					"faqQ7A1List4",
					"faqQ7A1List5"
				],
			},
			{ type: "span", id: "faqQ7A2Span" },
			{ type: "span", id: "faqQ7A3Span" },
			{ type: "span", id: "faqQ7A4Span" },
			{
				type: "list", items: [
					"faqQ7A4List0",
					"faqQ7A4List1",
					"faqQ7A4List2",
					"faqQ7A4List3",
					"faqQ7A4List4",
					"faqQ7A4List5",
					"faqQ7A4List6",
					"faqQ7A4List7",
					"faqQ7A4List8"
				],
			},
		],
	},
	{
		q: "faqQ8",
		a: [
			{ type: "span", id: "faqQ8A0Span" },
			{ type: "span", id: "faqQ8A1Span" },
			{
				type: "list", items: [
					"faqQ8A1List0",
					"faqQ8A1List1",
					"faqQ8A1List2",
					"faqQ8A1List3",
					"faqQ8A1List4"
				],
			},
			{ type: "span", id: "faqQ8A2Span" },
			{ type: "span", id: "faqQ8A3Span" }
		],
	},
	{
		q: "faqQ9",
		a: [
			{ type: "span", id: "faqQ9A0Span" }
		],
	},
	{
		q: "faqQ10",
		a: [
			{ type: "span", id: "faqQ10A0Span" }
		],
	},
	{
		q: "faqQ11",
		a: [
			{ type: "span", id: "faqQ11A0Span" },
			{ type: "span", id: "faqQ11A1Span" },
			{
				type: "list", items: [
					"faqQ11A1List0",
					"faqQ11A1List1",
					"faqQ11A1List2",
					"faqQ11A1List3"
				],
			},
			{ type: "span", id: "faqQ11A2Span" }
		],
	},
	{
		q: "faqQ12",
		a: [
			{ type: "span", id: "faqQ12A0Span" },
			{ type: "span", id: "faqQ12A1Span" },
			{ type: "span", id: "faqQ12A2Span" },
			{
				type: "list", items: [
					"faqQ12A2List0",
					"faqQ12A2List1",
					"faqQ12A2List2",
					"faqQ12A2List3",
					"faqQ12A2List4"
				],
			},
		],
	},
];