import { useTranslation } from "react-i18next";
import { DownloadApp } from "../../components/DownloadAppComponent/DownloadApp";
import PageBanners, { translateBanner } from "../../components/PageBanners/PageBanners";
import { LibraryBrands } from "../../components/ProductsComponent/LibraryBrands";
import { LibraryFinal } from "../../components/ProductsComponent/LibraryFinal";
import { ProductsPlating } from "../../components/ProductsComponent/ProductPlating";
import { ProductsAccessories } from "../../components/ProductsComponent/ProductsAccessories";
import { ProductsElectronics } from "../../components/ProductsComponent/ProductsElectronics";
import { ProductsFurniture } from "../../components/ProductsComponent/ProductsFurniture";

export const LibrariesPage = () => {
	const { t } = useTranslation();

	return (
		<div className={'page-margin'}>
			<PageBanners page={translateBanner("libraries", t)} />
			<DownloadApp />
			<ProductsFurniture />
			<LibraryBrands />
			<ProductsElectronics />
			<ProductsAccessories />
			<ProductsPlating />
			<LibraryFinal />
		</div>
	);
}