import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperShading } from '../SwiperUtils/Shading/SwiperShading';
import './WhyRapid.css';

export const WhyRapid = () => {
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const swiperRef = useRef(null);
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleResize = (e) => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	

	const swiperSettings = {
		ref: swiperRef,
		className: 'why-rapid-swiper',
		modules: [Navigation, Pagination],
		grabCursor: true,
		initialSlide: 1,
		slidesPerView: windowWidth > 890 ? 3 : windowWidth > 600 ? 2 : 1,
		navigation: {
			nextEl: nextRef.current,
			prevEl: prevRef.current,
		},
	}

	const items = [
		{
			img: "/assets/img/whyRapid/cloud.webp",
			alt: "cloud server icon",
			header: "cloudRender",
			span: "cloudRenderSpan"
		},
		{
			img: "/assets/img/whyRapid/realData.webp",
			alt: "furniture icons",
			header: "realData",
			span: "realDataSpan"
		},
		{
			img: "/assets/img/whyRapid/data.webp",
			alt: "a hand holding a tablet against a room",
			header: "roomScanning",
			span: "roomScanSpan"
		},
		{
			img: "/assets/img/whyRapid/VR.webp",
			alt: "VR glasses",
			header: "vrXRSupport",
			span: "vrXRSupportSpan"
		},
		{
			img: "/assets/img/whyRapid/window.webp",
			alt: "neural network",
			header: "aiAidedSupport",
			span: "aiAidedSupportSpan"
		}
	];

	return (
		<div className='component-wrapper  background-grey'>
			<div className='base-component component-padding column'>
				<h2 className='component-header-normal'>{t("whyRapidRender")}</h2>
				<SwiperShading color="238, 238, 238">
					<div className={"swiper-arrow-container"}>
						<img className="swiperArrow" alt={t("")} ref={prevRef}
							src="/assets/img/whyRapid/leftIcon.svg" />
						<img className="swiperArrow" alt={t("")} ref={nextRef}
							src="/assets/img/whyRapid/rightIcon.svg" />
					</div>
					<Swiper {...swiperSettings}>
						{
							items.map((item, index) => (
								<SwiperSlide key={index}>
									<img alt={item.alt} src={item.img} />
									<div className='swiper-text-container'>
										<h3>{t(item.header)}</h3>
										<span>{t(item.span)} </span>
									</div>
								</SwiperSlide>
							))
						}
					</Swiper>
				</SwiperShading>
			</div>
		</div>
	);
}