import { useEffect, useMemo, useState } from "react";

export function useOnScreen(ref) {

	const [isIntersecting, setIntersecting] = useState(false)

	const observer = useMemo(() => new IntersectionObserver(
		([entry]) => setIntersecting(entry.isIntersecting)
	), [])

	useEffect(() => {
		if (!ref.current) return
		observer.observe(ref.current.wrapper)
		return () => observer.disconnect()
	}, [observer, ref])

	return isIntersecting
}