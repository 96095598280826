
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { FreeMode, Keyboard, Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperShading } from '../SwiperUtils/Shading/SwiperShading';
import "./WhyRapidSwiper.css";

const commentArray = [
	{
		commentOwnerName: "commentOwner1Name",
		commentHead: "commentHead1",
		commentSpan: "commentSpan1",
		commentOwnerTitle: "commentOwner1Title",
		commentOwnerImage: "/assets/img/comments/commentOwnerImage1.webp",
		commentOwnerBrandImage: "/assets/img/comments/commentOwnerBrandImage1.webp"
	},
	{
		commentOwnerName: "commentOwner2Name",
		commentHead: "commentHead2",
		commentSpan: "commentSpan2",
		commentOwnerTitle: "commentOwner2Title",
		commentOwnerImage: "/assets/img/comments/commentOwnerImage2.webp",
		commentOwnerBrandImage: "/assets/img/comments/commentOwnerBrandImage2.webp"
	},
	{
		commentOwnerName: "commentOwner3Name",
		commentHead: "commentHead3",
		commentSpan: "commentSpan3",
		commentOwnerTitle: "commentOwner3Title",
		commentOwnerImage: "/assets/img/comments/commentOwnerImage3.webp",
		commentOwnerBrandImage: "/assets/img/comments/commentOwnerBrandImage3.webp"
	},
	{
		commentOwnerName: "commentOwner4Name",
		commentHead: "commentHead4",
		commentSpan: "commentSpan4",
		commentOwnerTitle: "commentOwner4Title",
		commentOwnerImage: "/assets/img/comments/commentOwnerImage4.webp",
		commentOwnerBrandImage: "/assets/img/comments/commentOwnerBrandImage4.webp"
	},
	{
		commentOwnerName: "commentOwner5Name",
		commentHead: "commentHead5",
		commentSpan: "commentSpan5",
		commentOwnerTitle: "commentOwner5Title",
		commentOwnerImage: "/assets/img/comments/commentOwnerImage5.webp",
		commentOwnerBrandImage: "/assets/img/comments/commentOwnerBrandImage5.webp"
	},
	{
		commentOwnerName: "commentOwner6Name",
		commentHead: "commentHead6",
		commentSpan: "commentSpan6",
		commentOwnerTitle: "commentOwner6Title",
		commentOwnerImage: "/assets/img/comments/commentOwnerImage6.webp",
		commentOwnerBrandImage: "/assets/img/comments/commentOwnerBrandImage6.webp"
	}
];

export const WhyRapidSwiper = () => {
	const { t } = useTranslation();
	const prevRef = useRef(null);
	const nextRef = useRef(null);

	const swiperSettings = {
		modules: [Navigation, Mousewheel, Keyboard, FreeMode],
		slidesPerView: "auto",
		spaceBetween: 40,
		grabCursor: true,
		centerInsufficientSlides: true,
		loop: false,
		navigation: {
			prevEl: prevRef.current,
			nextEl: nextRef.current,
		},
		keyboard: {
			enabled: true,
		},
		freeMode: {
			enabled: true,
		},
		mousewheel: {
			enabled: false,
		},
	};

	return (
		<div className="component-wrapper background-grey">
			<div className="base-component component-padding column">
				<div className="base-component-text-container-wide">
					<h2 className="component-header-large">{t("customerCommentsTitle")}</h2>
					<span className="base-component-span">{t("customerCommentSpan")}</span>
				</div>
				<SwiperShading color="238, 238, 238">
					<Swiper {...swiperSettings} className="whyRapidSwiper">
						{commentArray.map((item, index) => (
							<SwiperSlide key={"swiperSlides" + index}  className="why-rapid-cart">
								<div className="commentContainer">
									<div className="brand-logo-container">
										<img className="swiperCartBrandLogo" alt={"brand logo"} src={item.commentOwnerBrandImage} />
									</div>
									<h2 className="whyRapidCartHeader">{t(item.commentHead)}</h2>
									<p className="whyRapidCartSpan">{t(item.commentSpan)}</p>
								</div>
								<div className="comment-owner-container">
									<img height={"100%"} alt={"comment owner"} src={item.commentOwnerImage} />
									<div style={{ display: "flex", flexDirection: "column" }}>
										<h2 className="commentOwnerName"> {t(item.commentOwnerName)} </h2>
										<p className="commentOwnerTitle"> {t(item.commentOwnerTitle)} </p>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</SwiperShading>
			</div>
		</div>
	)
}