import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./auth/AuthProvider";
import { Authentication } from "./components/AuthenticationComponent/Authentication";
import { ScrollToTop } from "./components/Common/ScrollToTop";
import { Footer } from "./components/FooterComponent/Footer";
import { Header } from "./components/HeaderComponent/Header";
import { CompaniesPage } from "./pages/Companies/CompaniesPage";
import { ContactPage } from "./pages/Contact/ContactPage";
import { HomePage } from "./pages/Home/HomePage";
import { InteriorDesignersPage } from "./pages/InteriorDesigners/InteriorDesignersPage";
import CookiePolicyPage from "./pages/LegalNotices/CookiePolicyPage";
import PrivacyPolicyPage from "./pages/LegalNotices/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/LegalNotices/TermsOfUsePage";
import { LibrariesPage } from "./pages/Libraries/LibrariesPage";
import NotFound from "./pages/NotFound/NotFound";
import { ResetPasswordPage } from "./pages/ResetPassword/ResetPasswordPage";

i18n.use(initReactI18next)
	.use(I18nextBrowserLanguageDetector)
	.init({
		fallbackLng: "tr",
		resources: {
			en: { translation: require("../src/locales/en.json") },
			tr: { translation: require("../src/locales/tr.json") },
			fr: { translation: require("../src/locales/fr.json") },
			de: { translation: require("../src/locales/de.json") },
			it: { translation: require("../src/locales/it.json") },
			// ar: { translation: require("../src/locales/ar.json") },
			es: { translation: require("../src/locales/es.json") },
		},
	});

// const api = new Api();
// const fetchPages = async () => {
// 	try {
// 		return await api.fetchBlogPageUrls();
// 	} catch (error) {
// 		// console.error('Error during fetch:', error);
// 	}
// };
// const [blogThumbs, setPageThumbs] = useState([]);
// const [blogUrls, setPageUrls] = useState([]);

// useEffect(() => {
// 	fetchPages().then(pages => {
// 		if (pages === undefined) return;
// 		const urls = pages.data.map(p => {
// 			return {
// 				ar: p.urlAr,
// 				de: p.urlDe,
// 				en: p.urlEn,
// 				es: p.urlEs,
// 				fr: p.urlFr,
// 				it: p.urlIt,
// 				tr: p.urlTr
// 			}
// 		});
// 		const thumbs = pages.data.map(page => page.thumb);
// 		setPageUrls(urls);
// 		setPageThumbs(thumbs);
// 	});
// }, []);
// <Route path="/blogs" element={<BlogsPage thumbs={blogThumbs} urls={blogUrls} />} />
// 				{blogUrls && blogUrls.map((urls, index) => (
// 					blogThumbs[index] &&
// 					<Route key={index}
// 						path={`/blog${index}`}
// 						element={<BlogContainerPage urls={urls} />} />
// 				)
// 				)}

function App() {

	return (
		<AuthProvider>
			<Header />
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<HomePage />} />
				{/* <Route path='/pricingOptions' element={<PricingPage/>}/> */}
				<Route path="/libraries" element={<LibrariesPage />} />
				<Route path="/companies" element={<CompaniesPage />} />
				<Route path="/interiorDesigners" element={<InteriorDesignersPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/resetPassword/:id" element={<ResetPasswordPage />} />
				<Route path="/termsOfUse" element={<TermsOfUsePage />} />
				<Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
				<Route path="/cookiePolicy" element={<CookiePolicyPage />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
			<Authentication />
		</AuthProvider>
	);
}

export default App;
