import { useTranslation } from "react-i18next";

export const CadFileImport = () => {
	const { t } = useTranslation();
	return (
		<div className="base-component component-padding column">
			<div className="base-component-text-container-wide">
				<h2 className="component-header-normal">{t("functionElement11Header")}</h2>
				<span className="base-component-span">{t("functionElement11Span")} </span>
			</div>
			<img width="100%" height={"auto"} alt="CAD designs, a computer has RapidRender open, and an arrow from designs to computer"
				src="/assets/img/functionDescriptionsImages/cadFileImportImage.webp" />
		</div>
	)
}