const title = "SUGAR TECHNOLOGY YAZILIM A.Ş. ÇEREZ POLİTİKASI"

const article1 = {
    title: "Çerez Nedir?",
    content: [
        "a. Çerezler, web sitesi ve uygulamalar tarafından tarayıcınıza veya cihazınıza yerleştirilen küçük metin dosyalarıdır. Bu dosyalar, web sitesinin ve uygulamanın kullanımı hakkında bilgi toplamak ve kullanıcı deneyimini iyileştirmek amacıyla kullanılır.",
        "b. Çerezler, tarayıcınızın sabit diskine kaydedilir ve web sitesine dönüş yaptığınızda tanınmanızı sağlar. Çerezler, kişisel bilgilerinizi toplamaz."
    ]
};

const article2 = {
    title: "Çerez Kullanımı",
    content: [
        "a. Şirket, Web sitesi üzerinden çerezleri kullanarak aşağıdaki amaçları gerçekleştirebilir:",
        "i. Kullanıcı tercihlerini hatırlamak ve Web sitesinin kişiselleştirilmesini sağlamak.",
        "ii. Web sitesinin performansını izlemek ve iyileştirmek.",
        "iii. Kullanıcıların Web sitesini etkin bir şekilde kullanmalarını sağlamak.",
        "iv. Kullanıcı deneyimini geliştirmek ve kullanıcılara daha iyi hizmet sunmak.",
        "v. Şirket, Web sitesinde kullanılan çeşitli çerez türlerini kullanabilir. Örneğin: oturum çerezleri, kalıcı çerezler, analitik çerezler ve pazarlama çerezleri."
    ]
};

const article3 = {
    title: "Çerez Yönetimi",
    content: [
        "a. Kullanıcılar, çerezlerin kullanımını kabul etme veya reddetme seçeneğine sahiptir. Birçok tarayıcı, çerezleri otomatik olarak kabul eder, ancak tarayıcı ayarları üzerinden çerezleri reddetme veya çerez kullanımıyla ilgili uyarılar almayı tercih edebilirsiniz.",
        "b. Çerezleri devre dışı bırakmak, web sitesinin bazı özelliklerinin veya hizmetlerinin kullanılamayabileceği anlamına gelebilir."
    ]
};

const article4 = {
    title: "Üçüncü Taraf Çerezleri",
    content: [
        "a. Web sitesi, üçüncü taraf hizmet sağlayıcıları tarafından yerleştirilen çerezleri de kullanabilir. Bu çerezler, örneğin analitik hizmetler veya reklam ağları tarafından sağlanan çerezler olabilir. Üçüncü taraflar, kendi gizlilik politikaları çerçevesinde bilgilerini toplayabilir ve kullanabilir."
    ]
};

const article5 = {
    title: "Gizlilik Politikası",
    content: [
        "a. Çerezler, Web sitesindeki kullanıcı verileriyle ilişkilendirilmez ve Kişisel Verilerin Korunması Kanunu ve diğer ilgili mevzuata uygun olarak işlenir.",
        "b. Daha fazla bilgi için, Şirketin Gizlilik Sözleşmesi’ni inceleyebilirsiniz."
    ]
};

const article6 = {
    title: "Politika Değişiklikleri",
    content: [
        "a. Şirket, bu çerez politikasını kendi takdirine bağlı olarak dilediği zaman değiştirme hakkına sahiptir. Değişiklikler, Web sitesinde yayınlanarak yürürlüğe girer. Kullanıcılar, değişiklikleri takip etmekle sorumludur."
    ]
};

const cookiePolicyContent = {
    title: title,
    articles: [
        article1,
        article2,
        article3,
        article4,
        article5,
        article6
    ]
};

export { cookiePolicyContent };