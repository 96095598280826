import "./HeaderWithLine.css";

const HeaderWithLine = ({ color, size, weight, title, position, transform }) => {
	return (
		<div className={"header-with-line-container"} style={{ position: position, transform: transform }}>
			<div className="header-line mobile-hidden left" style={{
				backgroundColor: color
			}} />
			<h2
				className={"lined-header align-center no-margin component-header-" + size}
				style={{ fontWeight: weight, }} >{title}</h2>
			<div className="header-line mobile-hidden right" style={{
				backgroundColor: color
			}} />
		</div>
	);
}

export { HeaderWithLine };
