import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useOnScreen } from "../Common/ScreenUtil";
import "./MainVideoComponent.css";

const stepArray = [
	{
		name: "2D",
		subText: "Design",
		style: {
			fontWeight: "700",
			fontSize: "clamp(10px, 2vw, 30px)"
		},
		time: 14,
		isActive: false
	},
	{
		name: "3D",
		subText: "Design",
		style: {
			fontWeight: "700",
			fontSize: "clamp(10px, 2vw, 30px)"
		},
		time: 41,
		isActive: false
	},
	{
		name: "material",
		time: 64,
		style: {
			fontSize: "clamp(6px, 1.4vw, 20px)"
		},
		size: "1rem",
		isActive: false
	},
	{
		name: "experience",
		time: 93,
		style: {
			fontSize: "clamp(5px, 1vw, 14px)"
		},
		isActive: false
	},
	{
		name: "presentation",
		time: 109,
		style: {
			fontSize: "clamp(4px, .9vw, 11px)"
		},
		isActive: false
	},
	{
		name: "finish",
		time: 144,
		style: {
			fontSize: "clamp(5px, 1.1vw, 15px)"
		},
		isActive: false
	}
];

const mapTextArray = [
	"roomScanning",
	"readyRoom",
	"cadImport",
	"productPlacement",
	"ai",
	"floorAndWall",
	"changeFabric",
	"light",
	"vrExperience",
	"render",
	"postProcess",
	"proformAndReport",
	"finish"
];

const videoStampArray = [
	0,
	16.,
	42.,
	65.,
	94.,
	109.,
	118.,
	126.,
	144.
].map((stamp) => stamp / 146.0);

const imageStampArray = [
	0.,
	230.,
	388.,
	527.,
	670.,
	874.,
	1106.,
	1224.,
	1440.
].map((s) => s / 1440);

const imageStampArrayMobile = [
	0.,
	138.,
	215.,
	296.,
	367.,
	471.,
	620.,
	701.,
	788.
].map((s) => s / 788);

const passiveColor = "#8F8F8F";
const activeColor = "#1EA9CD";

export const MainVideoComponent = () => {
	const { t } = useTranslation();
	const [activeSteps, setActiveSteps] = useState(stepArray);
	const videoStamps = videoStampArray
	const [imageStamps, setImageStamps] = useState(null);
	const [playing, setPlaying] = useState(false);
	const playerRef = useRef(null);
	const mapRef = useRef(null);
	const onScreen = useOnScreen(playerRef);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		if (onScreen) {
			setPlaying(true);
		} else {
			setPlaying(false);
		}
	}, [onScreen]);

	useEffect(() => {
		function updateImageStamps() {
			if (windowWidth <= 890) {
				setImageStamps(imageStampArrayMobile);
			} else {
				setImageStamps(imageStampArray);
			}
		};

		function handleResize() {
			setWindowWidth(window.innerWidth);
		}

		updateImageStamps();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowWidth]);

	const handleStepClick = (index) => {
		const updatedSteps = activeSteps.map((step, i) => {
			if (i <= index) {
				return { ...step, isActive: true };
			} else {
				return { ...step, isActive: false };
			}
		});
		setActiveSteps(updatedSteps);
		playerRef.current.seekTo(activeSteps[index].time);
	};

	const calculateProgress = (played) => {
		const index = videoStamps.findIndex((stamp) => (played < stamp)) - 1;
		const perc = (played - videoStamps[index]) / (videoStamps[index + 1] - videoStamps[index]) * 100;
		const result = perc * (imageStamps[index + 1] - imageStamps[index]);

		return ((imageStamps[index] * 100) + result);
	}

	const handleProgress = (progress) => {
		setActiveSteps(activeSteps.map((step) => {
			return ({
				...step,
				isActive: progress.playedSeconds >= step.time
			});
		}));
		mapRef.current.style.clipPath = `inset(0 0 0 ${calculateProgress(progress.played)}%)`;
	};

	const calculateMapClick = (position) => {
		const index = imageStamps.findIndex((stamp) => (position < stamp)) - 1;
		const perc = (position - imageStamps[index]) / (imageStamps[index + 1] - imageStamps[index]);
		const result = perc * (videoStamps[index + 1] - videoStamps[index]);

		return (videoStamps[index] + result);
	};

	const handleMapClick = (event) => {
		const x = event.clientX - mapRef.current.getBoundingClientRect().left;
		const width = mapRef.current.getBoundingClientRect().width;
		const progress = x / width;
		const result = calculateMapClick(progress);

		playerRef.current.seekTo(Math.min(result, 1.0));
	};

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
				<div className="base-component-text-container-wide">
					<h2 className="component-header-large align-center">
						{t("mainVideoTitle")}
					</h2>
					<span className="base-component-span">
						{t("mainVideoSpan")}
					</span>
				</div>
				<div className="how-it-works-steps">
					{activeSteps.map((step, index) => {
						return (
							<Fragment key={index}>
								<div
									key={index}
									className="how-it-works-step"
									onClick={() => handleStepClick(index)}>
									<img
										src={`/assets/img/mainVideoComponent/c${activeSteps[index].isActive ? '1' : '2'}.svg`}
										alt="A circle, representing a step in the process"
										className="how-it-works-step-img" />
									<span className="how-it-works-step-text"
										style={{
											...step.style,
											color: step.isActive ? "#FFF" : "#000"
										}}>
										{t(step.name)}
									</span>
									{
										step.subText &&
										<span className="how-it-works-step-subtext"
											style={{
												color: step.isActive ? "#FFF" : "#000"
											}}>
											{t(step.subText)}
										</span>
									}
								</div>
								{
									index < activeSteps.length - 1 &&
									<div
										className="horizontal-line"
										style={{
											backgroundColor: activeSteps[index + 1].isActive ? activeColor : passiveColor
										}} />
								}
							</Fragment>
						);
					})}
				</div>
				<div className="how-it-works-map-wrapper">
					<div className="how-it-works-map" onClick={handleMapClick}>
						<img className="how-it-works-map-img"
							ref={mapRef}
							style={{
								zIndex: 1,
							}}
							src={`/assets/img/mainVideoComponent/map1${windowWidth <= 890 ? "_mobile" : ''}.webp`}
							alt="A map of the process"
						/>
						<img className="how-it-works-map-img"
							src={`/assets/img/mainVideoComponent/map2${windowWidth <= 890 ? "_mobile" : ''}.webp`}
							alt="A map of the process" />
					</div>
					<div className="how-it-works-map-text-container">
						{
							mapTextArray.map((text, index) => {
								return (
									<span key={index} id={"map-text" + index} className="how-it-works-map-text">
										{t(text)}
									</span>
								);
							})
						}
					</div>
				</div>
					<ReactPlayer
						ref={playerRef}
						url='https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugartechvideos/Homepage+steps-appletv.mp4'
						width="100%"
						height="100%"
						playing={playing}
						loop={true}
						muted={true}
						progressInterval={100}
						onProgress={handleProgress}
						controls={true}
						config={{ file: { attributes: { controlsList: 'nodownload' } } }}
					/>
			</div>
		</div>
	);
};