import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { HeaderWithLine } from "../Common/HeaderWithLine"
import "./Products.css"

export const ProductsAccessories = () => {
	const { t } = useTranslation()
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const productsArray = [
		{
			url: '/assets/img/productImages/a1.webp',
			header: t("accessories1"),
			productId: "Designer_38721",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a2.webp',
			header: t("accessories2"),
			productId: "Designer_38674",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a3.webp',
			header: t("accessories3"),
			productId: "Designer_38688",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a4.webp',
			header: t("accessories4"),
			productId: "Designer_38633",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a5.webp',
			header: t("accessories5"),
			productId: "Designer_38634",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a6.webp',
			header: t("accessories6"),
			productId: "Designer_38588",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a7.webp',
			header: t("accessories7"),
			productId: "Designer_38675",
			companyId: "1252",
		},
		{
			url: '/assets/img/productImages/a8.webp',
			header: t("accessories8"),
			productId: "Designer_38606",
			companyId: "1252",
		},
	]

	const sugarRef = useRef();
	const popupRef = useRef();

	const handleEscape = (e) => {
		if (e.key === 'Escape') {
			onClose();
		}
	};

	const openPopup = (index) => {
		const existingScript = document.getElementById('sugar-model-viwer-js');
		if (!existingScript) {
			const script = document.createElement('script');
			script.src = 'https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugar-model-viewer-test.js';
			script.id = 'sugar-model-viwer-js';
			script.type = 'module';
			document.body.appendChild(script);
		}

		const sugarModelViewerContainer = sugarRef;
		sugarModelViewerContainer.current.innerHTML = "";
		setTimeout(() => {
			sugarModelViewerContainer.current.innerHTML = `
            <sugar-model-viewer class="modelViewer" id="sugar" shadow-intensity="1" show-model-group style={{ width:"100%" ,height: "100%", background: "white",margin: "auto" ,display: "block" ,marginBottom: "60px" ,paddingBottom: "60px"}}
            ar-scale="fixed" show-option="" camera-controls="" ar-status="not-presenting" camera-down-lock="true"  camera-orbit="15deg 55deg auto" template="advanced" showDimension="true"
            product_id="${productsArray[index].productId}" company_id=${productsArray[index].companyId} exposure="1"  joint-show="" selectColor="#1EA9CD" qr="" is-panel-open="true" >
            </sugar-model-viewer>`;
		}, 10);

		popupRef.current.style.display = "flex";
		window.addEventListener('keydown', handleEscape);
	};

	const onClose = () => {
		popupRef.current.style.display = "none";
		sugarRef.current.innerHTML = "";
		window.removeEventListener('keydown', handleEscape);
	};

	return (
		<div className="component-wrapper">
			<div className="base-component component-padding column">
			<HeaderWithLine
				title={t("accessories")}
				color="#1EA9CD"
				size="large"
				position={windowWidth <= 890 ? "relative" : "absolute"}
				transform={windowWidth <= 890 ? "" : windowWidth <= 1050 ? "translateY(-155%)" : "translateY(-208%)"} />
				<div className='base-component-text-container-wide'>
					<span className='base-component-span'>{t("accessoriesSpan")}</span>
				</div>
				<div className="product-item-container">
					{productsArray.map((item, index) => (
						<div key={"accessories" + index} className="productItem" onClick={() => openPopup(index)}>
							<img src={item.url} className="productsImage" alt="Product" />
							<span className="productItemHeader">{item.header}</span>
						</div>
					))}
				</div>
				<div className="popupOverlay" ref={popupRef}>
					<div className="popup">
						<div className="closeButtons" onClick={onClose}>
							<img className="closeIcon" alt="popup icon" src={"/assets/img/productImages/popupVector2.svg"}></img>
						</div>
						<div className="sugarModelViewerContainer" ref={sugarRef}></div>
					</div>
				</div>
			</div>
		</div>
	)
}