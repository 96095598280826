import { useTranslation } from "react-i18next";

export const ProjectManagment = () => {
	const { t } = useTranslation();
	return (
		<div className="base-component component-padding row-reverse tight">
			<div className="base-component-text-container">
				<h2 className="component-header-normal">{t("functionElement14Header")}</h2>
				<span className="base-component-span">{t("functionElement14Span")} </span>
			</div>
			<div className="media-container">
				<img width="100%" alt="a laptop, a phone, a smart watch and a tablet"
				src="/assets/img/functionDescriptionsImages/deviceSupport.webp"></img>
			</div>
		</div>
	)
}