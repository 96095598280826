import { useTranslation } from "react-i18next";

export const CloudRender = () => {
	const { t } = useTranslation();
	return (
		<div className="base-component component-padding row tight">
			<div className="base-component-text-container">
				<h2 className="component-header-normal">{t("functionElement8Header")}</h2>
				<span className="base-component-span">{t("functionElement8Span")}</span>
			</div>
			<img style={{width: "auto", height: "clamp(200px, 50vw, 600px)"}} alt="cloud render"
				src="/assets/img/functionDescriptionsImages/cloudRenderImage.webp" />
		</div>
	)
}