import React, { useEffect, useState } from 'react';
import { Contact } from '../../components/ContactComponent/Contact';
import { SSS } from '../../components/S.S.S/SSS';

export const ContactPage = props => {
	const [isTablet, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 800);
			setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);

		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className={`${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} page-margin contactPage`}>
			<Contact></Contact>
			<SSS></SSS>
		</div>
	);
}