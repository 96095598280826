import axios from "axios";

export default class Api {
	constructor() {
		this.api_token = null;
		this.client = null;
		this.api_url = process.env.REACT_APP_API_ENDPOINT;
	}

	init = () => {
		this.api_token = window.localStorage.getItem("authorisationCode");

		let headers = {
			Accept: "application/json",
		};

		if (this.api_token) {
			headers.Authorization = `Bearer ${this.api_token}`;
		}

		this.client = axios.create({
			baseURL: this.api_url,
			timeout: 60000,
			headers: headers,
		});

		return this.client;
	};

	loginUser = async (data) => {
		return this.init().post(`ssouser/login`, data);
	};

	createUser = async (data) => {
		return this.init().post(`ssouser/create`, data);
	};

	sendLink = async () => {
		return this.init().post(`demolink/sendLink`);
	};

	fetchCountries = async () => {
		return this.init().post(`ssouser/getCountries`);
	};

	contact = async (contactForm) => {
		return this.init().post(`contact/form`, contactForm);
	};

	institutionalForm = async (inputs) => {
		const formData = new FormData();
		const blob = new Blob([JSON.stringify(inputs)], { type: 'application/json' });
		formData.append("institutionalDemoForm", blob);
		
		return this.init().post(`contact/institutionalDemo`, formData);
	};

	subscribe = async (email) => {
		return this.init().post(`contact/subscribe`, email);
	};

	requestResetPassword = async (email) => {
		return this.init().post(`ssouser/requestResetPassword?email=${email}`);
	};
	
	resetPassword = async (data) => {
		return this.init().post(`ssouser/resetPassword`, data);
	};

	fetchPackages = async () => {
		return this.init().post(`packages/fetchPackages`);
	};

	// fetchBlogPageUrls = async () => {
	// 	return this.init().get(`blogs/urls`);
	// };

	fetchCalculatedPackages = async (selectedCurrency, type, promoCode) => {
		const formData = new FormData();
		formData.append("currency", new Blob([selectedCurrency], { type: "application/json" }));
		formData.append("type", new Blob([type], { type: "application/json" }));
		formData.append("promoCode", new Blob([promoCode], { type: "application/json" }));
		return this.init().post(`packages/fetchCalculatedPackage`, formData);
	};

	createPayment = async (card, calculatedPackage, promoCode) => {
		const formData = new FormData();
		formData.append("card", new Blob([JSON.stringify(card)], { type: "application/json" }));
		formData.append("promoCode", new Blob([promoCode], { type: "text/plain" }));
		return this.init().post(`payments/newPayment?packageId=${calculatedPackage.id}`, formData);
	};
}
