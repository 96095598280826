import { useTranslation } from "react-i18next";
import { DownloadApp } from "../../components/DownloadAppComponent/DownloadApp";
import { Functions } from "../../components/FunctionsComponent/FunctionsComponent";
import PageBanners, { translateBanner } from "../../components/PageBanners/PageBanners";

export const InteriorDesignersPage = () => {
	const { t } = useTranslation();

	return (
		<div className={"page-margin"}>
			<PageBanners page={translateBanner("interiorDesigners", t)} />
			<DownloadApp />
			<Functions />
		</div>
	)
}