import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import { useApi } from "../../api/useApi";
import { PopupError, PopupSuccess } from '../Common/Popup';
import './Contact.css';

export const ContactInput = () => {
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [mail, setMail] = useState("");
	const [explanation, setExplanation] = useState("");
	const [phone, setPhone] = useState("");
	const [nameError, setNameError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [explanationError, setExplanationError] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [responseOk, setResponseOk] = useState(false);
	const [captcha, setCaptcha] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth)

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	});

	const api = useApi();

	const handlePopupClose = () => {
		setShowPopup(false);
		window.removeEventListener('buttonDown', handleEscape);
	}

	const handleEscape = (e) => {
		if (e.key === "Escape") {
			handlePopupClose();
		}
	}

	const validateInput = () => {
		let err = false;

		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

		if (!(emailRegex.test(mail))) {
			setEmailError(true);
			err = true;
		}
		if (name.length === 0) {
			setNameError(true);
			err = true;
		}
		if (phone.length !== 12) {
			setPhoneError(true);
			err = true;
		}
		if (explanation.length === 0) {
			setExplanationError(true);
			err = true;
		}
		setTimeout(() => {
			setNameError(false);
			setEmailError(false);
			setPhoneError(false);
			setExplanationError(false);
		}, 5000);
		return err ? false : true;
	}

	const handleSubmit = async (event) => {
		if (captcha && validateInput()) {
			event.preventDefault();

			submitForm();

			window.addEventListener('buttondown', handleEscape)
		}
	}

	const submitForm = async () => {
		const contactForm = {
			fullName: name,
			email: mail,
			phone: phone,
			message: explanation,
		}

		const formData = new FormData();
		formData.append("contactForm", new Blob([JSON.stringify(contactForm)], { type: 'application/json' }));
		try {
			document.body.style.cursor = "wait";
			const response = await api.contact(formData);
			if (response.status === 200) {
				setResponseOk(true);
				setShowPopup(true);
			}
		} catch (e) {
			setResponseOk(false);
			setShowPopup(true);
		}
		document.body.style.cursor = "default";
	}

	return (
		<div className='contactInputComponent'>
			<div className='inputsRestrictiveContainer' onSubmit={(e) => { return false; }}>
				<h2 className='contactInputHeader'>{t("contactInputHeader")}</h2>
				<div className='input-wrapper'>
					<input
						className={`form-input ${nameError ? "error-border" : ""}`}
						placeholder={t('fullName')}
						value={name}
						onChange={(e) => setName(e.target.value)} />
					{nameError &&
						<div className='invalid-input-error'>
							<img alt="error" src="/assets/img/footerImages/error.svg" />
						</div>
					}
				</div>
				<div className='input-wrapper'>
					<input
						className={`form-input ${emailError ? "error-border" : ""}`}
						type='email'
						placeholder={t('email')}
						value={mail}
						onChange={(e) => setMail(e.target.value)} />
					{emailError &&
						<div className='invalid-input-error'>
							<img alt="error" src="/assets/img/footerImages/error.svg" />
						</div>
					}
				</div>
				<div className='input-wrapper'>
					<PhoneInput
						className={`form-input ${phoneError ? "error-border" : ""}`}
						placeholder={t("phoneNumber")}
						country={'tr'}
						value={phone}
						buttonStyle={{
							borderRadius: "9px 0 0 9px",
						}}
						defaultErrorMessage={t('footerErrorMessage')}
						onChange={setPhone}>
					</PhoneInput>
					{phoneError &&
						<div className='invalid-input-error'>
							<img alt="error" src="/assets/img/footerImages/error.svg" />
						</div>
					}
				</div>
				<div className='input-wrapper'>
					<textarea
						className={`explanationInput ${explanationError ? "error-border" : ""}`}
						placeholder={t("explanationInput")}
						value={explanation}
						onChange={(e) => setExplanation(e.target.value)} />
				</div>
				<ReCAPTCHA
					sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
					style={{ alignSelf: "flex-start" }}
					className="reCaptcha"
					size={windowWidth <= 890 ? "compact" : "normal"}
					onChange={val => setCaptcha(val)} />
				<div disabled={!captcha} className='submitButton'
					style={{ background: captcha ? "#1EA9CD" : "#495057", cursor: captcha ? "pointer" : "default", }}
					onClick={handleSubmit}>
					<span className='submitButtonSpan'>{t("submit")}</span>
				</div>
			</div>
			{showPopup &&
				(responseOk
					? <PopupSuccess onClose={handlePopupClose} />
					: <PopupError onClose={handlePopupClose} />)}
		</div>
	);
}