import { useTranslation } from "react-i18next";
import "./CompaniesSupport.css";

export const CompaniesSupport = () => {
	const { t } = useTranslation();

	return (
		<div className="customer-support-component">
			<div className="component-padding customer-support-container">
				<div className="customer-support-text-container">
					<h2> {t("support")} </h2>
					<h2 className="contact-number">444 06 93</h2>
					<p> {t("supportText1")} </p>
					<p> {t("supportText2")} </p>
				</div>
				<div className="customer-support-image-container">
					<img className="customer-support-image"
						alt={t("customerSupportImageAlt")}
						src="/assets/img/companiesSupport.svg" />
				</div>
			</div>
		</div>
	);
}