import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useOnScreen } from '../Common/ScreenUtil';
import './WhatsNew.css';

export const WhatsNew = () => {
	const { t } = useTranslation();
	const [playing, setPlaying] = useState(false);
	const playerRef = useRef(null);
	const onScreen = useOnScreen(playerRef);

	useEffect(() => {
		if (onScreen) {
			setPlaying(true);
		} else {
			setPlaying(false);
		}
	}, [onScreen]);

	const sources = [
			"https://s3.eu-central-1.amazonaws.com/cdn.sugartech/local/rapidRenderVideos/yapayZeka.webm",
			"https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugartechvideos/yapayZeka.mp4",
	];

	return (
		<div className='component-wrapper'>
			<div className='base-component component-padding column'>
				<div className='base-component-text-container-wide'>
					<h2 className='component-header-large'>{t("whatsNewTitle")}</h2>
					<span className='base-component-span'>{t("whatsNewSpan")}</span>
				</div>
				<ReactPlayer
					ref={playerRef}
					url={sources}
					width="100%"
					height="100%"
					controls={true}
					loop={true}
					muted={true}
					config={{ file: { attributes: { controlsList: 'nodownload' } } }}
					playing={playing}
				/>
			</div>
		</div>
	);
}