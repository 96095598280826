import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderWithLine } from "../Common/HeaderWithLine";
import "./Products.css";

export const ProductsElectronics = () => {
	const { t } = useTranslation();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const productsArray = [
		{
			url: '/assets/img/productImages/e1.webp',
			header: t("electronics1"),
			productId: "arcadia_34073",
			companyId: "6",
		},
		{
			url: '/assets/img/productImages/e2.webp',
			header: t("electronics2"),
			productId: "20263678",
			companyId: "6",
		},
		{
			url: '/assets/img/productImages/e3.webp',
			header: t("electronics3"),
			productId: "Designer_33277",
			companyId: "45",
		},
		{
			url: '/assets/img/productImages/e4.webp',
			header: t("electronics4"),
			productId: "Mimar_32150",
			companyId: "45",
		},
		{
			url: '/assets/img/productImages/e5.webp',
			header: t("electronics5"),
			productId: "1952_16603",
			companyId: "6",
		},
		{
			url: '/assets/img/productImages/e6.webp',
			header: t("electronics6"),
			productId: "20242933",
			companyId: "6",
		},
		{
			url: '/assets/img/productImages/e7.webp',
			header: t("electronics7"),
			productId: "20264346",
			companyId: "6",
		},
		{
			url: '/assets/img/productImages/e8.webp',
			header: t("electronics8"),
			productId: "20261944",
			companyId: "6",
		},
	]

	const sugarRef = useRef();
	const popupRef = useRef();

	const handleEscape = (e) => {
		if (e.key === 'Escape') {
			onClose();
		}
	};

	const openPopup = (index) => {
		const existingScript = document.getElementById('sugar-model-viwer-js');
		if (!existingScript) {
			const script = document.createElement('script');
			script.src = 'https://s3.eu-central-1.amazonaws.com/cdn.sugartech/mottobucket/CDN/sugar-viewer/sugar-model-viewer-test.js';
			script.id = 'sugar-model-viwer-js';
			script.type = 'module';
			document.body.appendChild(script);
		}
		
		const sugarModelViewerContainer = sugarRef;
		setTimeout(() => {
			sugarModelViewerContainer.current.innerHTML = `
            <sugar-model-viewer class="modelViewer" id="sugar" ar="" shadow-intensity="1" show-model-group style={{ width:"100%", height: "100%", background: "white", margin: "auto", display: "block", marginBottom: "60px", paddingBottom: "60px"}}
            ar-scale="fixed" show-option="" camera-controls="" ar-status="not-presenting" camera-down-lock="true" camera-orbit="15deg 55deg auto" template="advanced" showDimension="true"
            product_id="${productsArray[index].productId}" company_id=${productsArray[index].companyId} exposure="1"  joint-show="" selectColor="#1EA9CD" qr="" is-panel-open="true" >
            </sugar-model-viewer>`;
		}, 10);
		
		popupRef.current.style.display = "flex";
		window.addEventListener('keydown', handleEscape);
	};

	const onClose = () => {
		popupRef.current.style.display = "none";
		sugarRef.current.innerHTML = "";
		window.removeEventListener('keydown', handleEscape);
	};

	return (
		<div className="component-wrapper background-grey">
			<div className="base-component component-padding column">
			<HeaderWithLine
				title={t("electronics")}
				color="#1EA9CD"
				size="large"
				position={windowWidth <= 890 ? "relative" : "absolute"}
				transform={windowWidth <= 890 ? "" : windowWidth <= 1050 ? "translateY(-155%)" : "translateY(-208%)"} />
				<div className='base-component-text-container-wide'>
					<span className='base-component-span'>{t("electronicsSpan")}</span>
				</div>
				<div className="product-item-container" >
					{productsArray.map((item, index) => (
						<div key={"electronics" + index} className="productItem" onClick={() => openPopup(index)}>
							<img src={item.url} className="productsImage" alt="Product" />
							<h2 className="productItemHeader">{item.header}</h2>
						</div>
					))}
				</div>
				<div className="popupOverlay" ref={popupRef}>
					<div className="popup">
						<div className="closeButtons" onClick={onClose}>
							<img className="closeIcon" alt="popup vector" src={"/assets/img/productImages/popupVector2.svg"} />
						</div>
						<div className="sugarModelViewerContainer" ref={sugarRef}></div>
					</div>
				</div>
			</div>
		</div>
	)
}