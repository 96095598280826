import { useTranslation } from "react-i18next";

export const Commission = () => {
	const { t } = useTranslation();
	return (
		<div className="base-component component-padding tight">
			<div className="base-component-text-container">
				<h2 className="component-header-normal">{t("functionElement15Header")}</h2>
				<span className="base-component-span">{t("functionElement15Span")}</span>
			</div>
			<div className="media-container">
				<img className="base-component-image"
					alt="a rising graph, some gold coins and cash, a person looking at a laptop while sitting on gold coins"
					src="/assets/img/functionDescriptionsImages/commissionImage.svg" />
			</div>
		</div>
	);
}